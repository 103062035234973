// Inform Beckend if any modifications done in this file
export const salutationsMasters: string[] = ['Mr.', 'Ms.', 'M/S.', 'Mrs.'];

export const applicantStatusMasters: { [key: string]: string } = {
  Individual: 'Individual (Resident)',
  NRI: 'Individual (NRI)',
};

export const debitingFreqMaster: { [key: string]: string } = {
  monthly: 'Monthly',
  na: 'NA',
};

export const YearEndMaster: { [key: string]: string } = {
  financial: 'Financial',
  na: 'NA',
};

export const nationalitiesMasters: string[] = ['Indian', 'Others'];

export const fatherOrSpouseSelectMasters: { [key: string]: string } = {
  father: 'Father',
  spouse: 'Spouse',
};

export const maritalStatusMasters: { [key: string]: string } = {
  single: 'Single',
  married: 'Married',
  // other: 'Others',
};

export const educationQualificationMasters: { [key: string]: string } = {
  doctorate: 'Doctorate',
  graduate: 'Graduate',
  high_school: 'High School',
  illiterate: 'Illiterate',
  post_graduate: 'Post Graduate',
  professional: 'Professional',
  under_high_school: 'Under High School',
  others: 'Others',
};

export const CustodianMaster: string[] = ['Deutsche Bank'];

export const occupationDetailsMasters: { [key: string]: string } = {
  agriculturist: 'Agriculturist',
  business: 'Business',
  farmer: 'Farmer',
  forex_dealer: 'Forex Dealer',
  government_service: 'Government Service',
  housewife: 'Housewife',
  private_sector: 'Private Sector',
  professional: 'Professional',
  public_sector: 'Public Sector',
  retired: 'Retired',
  service: 'Service',
  student: 'Student',
  others: 'Others',
};

export const grossAnnualMasters: { [key: string]: string } = {
  below_1_LAC: 'BELOW 1 LAC',
  '1_to_5_lac': '1 TO 5 LAC',
  '5_to_10_LAC': '5 TO 10 LAC',
  '10_to_25_lac': '10 TO 25 LAC',
  '25_lac_to_1_cr': '25 LAC - 1 CR',
  greater_than_1_cr: 'GREATER THAN 1 CR',
};

export const PEPsMasters: string[] = ['I am PEP', 'I am Related to PEP', 'Not Applicable'];

export const CompanyMasters: string[] = ['Company', 'Sector', 'Industry'];

export const addressTypesMasters: string[] = [
  'Resident/Business',
  'Resident',
  'Business',
  'Registered Office',
  'Unspecified',
];

export const genderMasters: string[] = ['Male', 'Female', 'Others'];

export const IdentityProofsMasters: string[] = [
  'Photo PAN Card',
  'Passport',
  "Voter's ID",
  'Driving License',
  'Aadhaar Card',
];

export const AddressProofsMasters: string[] = [
  'Passport',
  "Voter's ID",
  'Ration Card',
  'Electricity Bill',
  'Landline/Telephone Bill',
  'Gas Bill',
  'Latest Bank Statement/Passbook',
  'Latest Depository Account Statement',
];

export const BankAccountProofsMasters: string[] = [
  'Bank Statement with IFSC printed on it',
  'Cancelled cheque leaf verified',
  'Letter from the bank giving account details',
  'Passbook (showing bank account details)',
];

export const DrawDownMasters: number[] = [1, 2, 3, 4];

export const filterApplicationTypes = [
  { key: 'individual', value: 'Individual (Resident)' },
  { key: 'non_individual', value: 'Non-Individual' },
  { key: 'poa', value: 'Individual (POA)' },
  { key: 'nri', value: 'Individual (NRI)' },
  { key: 'nri_poa', value: 'Individual (NRI-POA)' },
];

export const ModeOfHoldingTypes = [
  { key: 'single', value: 'Single' },
  { key: 'either or survivor,joint', value: 'Joint' },
];

export const IMAGE_UPLOAD_FORMATS = ['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'];

export const INVESTOR_IMAGE_UPLOAD_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

export const APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};

export const SUPPORT_STATUS: { [key: string]: string } = {
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_fundmanager: 'Approved by investment manager',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
};

export const POA_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const ESIGN_STATUS: { [key: string]: string } = {
  NOT_GENERATED: 'notgenerated',
  PENDING_SIGNATURE: 'pendingsignature',
  SIGNED: 'signed',
  EXPIRED: 'expired',
};

export enum USER_ROLES {
  SUPER_ADMIN = 'superadmin',
  AMC_ADMIN = 'amcadmin',
  RM = 'rm',
  DISTRIBUTOR = 'distributor',
  INVESTOR = 'investor',
  FUND_MANAGER = 'fundmanager',
  TRUSTEE = 'trustee',
  WITNESS = 'witness',
  APPLICATION = 'application',
  SUBDISTRIBUTOR = 'subdistributor',
  POAAPPROVER = 'poaapprover',
  AMCAPPROVER = 'amcapprover',
  AUTHORISEDSIGNATORY = 'authorisedsignatory',
  AMCAUTHORISEDSIGNATORY = 'amcauthorisedsignatory',
}

export const ApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } = {
  [USER_ROLES.POAAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_poa_approver: 'Pending',
  },
  [USER_ROLES.AMCAPPROVER]: {
    ...POA_APPLICATION_STATUS,
    sent_to_amc_approver: 'Pending',
  },
};
export const ApplicationStatusBasedOnRoleAndAmcApproverStatus: {
  [key: string]: { [key: string]: { [key: string]: string } };
} = {
  [USER_ROLES.AMCAPPROVER]: {
    ['pending']: {
      ...POA_APPLICATION_STATUS,
      sent_to_amc_approver: 'Pending',
    },
  },
};

export const Relations: { [key: string]: string } = {
  brother: 'Brother',
  daughter: 'Daughter',
  father: 'Father',
  grand_daughter: 'Grand-Daughter',
  grand_Father: 'Grand-Father',
  grand_mother: 'Grand-Mother',
  grand_son: 'Grand-Son',
  mother: 'Mother',
  self: 'Self',
  sister: 'Sister',
  son: 'Son',
  spouse: 'Spouse',
  // not_provided: 'Not Provided',
  others: 'Others',
};

export const NomineeRelations: { [key: string]: string } = {
  mother: 'Mother',
  father: 'Father',
  daughter: 'Daughter',
  son: 'Son',
  spouse: 'Spouse',
  brother: 'Brother',
  sister: 'Sister',
  others: 'Others',
};

export const adminSupportMaster: { [key: string]: string } = {
  unlock_user: 'Unlock User',
  check_whitelisted: 'Check Whitelisted',
  whitelist_user: 'Whitelist User',
  unwhitelist_user: 'Unwhitelist User',
  change_expired_status: 'Change Expired Status',
  change_status_to_draft: 'Change Status to draft',
};

export const IS_DOB_APPLICABLE_FOR_AMC = true;

export const REJECTED_ROLES: { [key: string]: string } = {
  poaapprover: 'POA Approver',
  amcapprover: 'Checker',
};

export const investorTypeMasters: { [key: string]: string } = {
  corporate: 'Corporate',
  trust: 'Trust',
  partnership_firm: 'Partnership Firm',
  huf: 'HUF',
  govt_bodies: 'Army/ Government Bodies',
  registered_society: 'Registered Society',
  fii_fpi: 'FII/FPI',
  bank: 'Bank',
  institutional_investor: 'Institutional Investor',
  unincorporated_or_body_of_individuals: 'Unincorporated association or a body of individuals',
  foreign_entity: 'Foreign Entity',
};
export const IS_PENNYDROP_APPLICABLE_FOR_AMC = true;

export const getGroupNameMaster = ['Group-A', 'Group-B', 'Group-C'];

export const NON_INDIVIDUAL_APPLICATION_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
  approved_by_fundmanager: 'Approved by investment manager',
  approved_by_trustee: 'Approved by trustee',
  invitationexpired: 'Invitation Expired',
  signed: 'Completed',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const CpUboCodesMaster: { [key: string]: string } = {
  C01: '- CP of legal person-ownership',
  C02: '- CP of legal person-other means',
  C03: '- CP of legal person-senior managing official',
  C04: '- CP of legal arrangement-trust-settlor',
  C05: '- CP of legal arrangement-trust-trustee',
  C06: '- CP of legal arrangement-trust-protector',
  C07: '- CP of legal arrangement-trust-beneficiary',
  C08: '- CP of legal arrangement-trust-other',
  C09: '- CP of legal arrangement-trust-other-settlor-equivalent',
  C10: '- CP of legal arrangement-trust-other-trustee-equivalent',
  C11: '- CP of legal arrangement-trust-other-protector-equivalent',
  C12: '- CP of legal arrangement-trust-other-beneficiary-equivalent',
  C13: '- CP of legal arrangement-trust-other-other-equivalent',
  C14: '- Unknown',
};
export const controllingPersonsNationalitiesMasters: string[] = ['Indian', 'Others'];

//Application types
export enum APPLICATION_TYPE {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
}

export const AMC_APPROVER_CHECK_FOR_INDIVIDUAL = true;

export const ENABLE_EMAIL_OTP = true;

export const APPLICATION_STATUS_AMCAPPROVER_individual: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
};
export const IndividualApplicationStatusBasedOnRole: { [key: string]: { [key: string]: string } } =
  {
    [USER_ROLES.AMCAPPROVER]: {
      ...APPLICATION_STATUS_AMCAPPROVER_individual,
      sent_to_amc_approver: 'Pending',
    },
  };
export const IndividualApplicationStatusBasedOnRoleAndAmcApproverStatus: {
  [key: string]: { [key: string]: { [key: string]: string } };
} = {
  [USER_ROLES.AMCAPPROVER]: {
    ['pending']: {
      ...APPLICATION_STATUS_AMCAPPROVER_individual,
      sent_to_amc_approver: 'Pending',
    },
  },
};

export const APPLICATION_LISTING_STATUS_TIMELINE: { [key: string]: string } = {
  application_created: 'Application Created',
  draft: 'Draft',
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
};

export const ROLE_TIMELINE: { [key: string]: string } = {
  superadmin: 'Super admin',
  amcadmin: 'amc admin',
  rm: 'rm',
  distributor: 'distributor',
  investor: 'investor',
  fundmanager: 'Fundmanager',
  trustee: 'Trustee',
  witness: 'Witness',
  application: 'application',
  subdistributor: 'sub distributor',
  poaapprover: 'poa approver',
  amcapprover: 'Checker',
  authorisedsignatory: 'authorized signatory',
  amcauthorisedsignatory: 'amc authorized signatory',
  groupsignatory: 'authorized signatory',
};

export const APPLICATION_LISTING_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_applicant1: 'Sent to applicant(s)',
  approved_by_applicant1: 'Approved by 1st Applicant',
  approved_by_applicant2: 'Approved by 2nd Applicant',
  approved_by_applicant3: 'Approved by 3rd Applicant',
  approved_by_trustee: 'Approved by Trustee',
  approved_by_fundmanager: 'Approved by investment manager',
  invitationexpired: 'Invitation Expired',
  completed: 'Completed',
  signed: 'Completed',
  rejected: 'Rejected',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  approved_by_authorised_signatories: 'Approved by authorized signatories',
};
export const APPLICATION_LISTING_CHECKER_STATUS: { [key: string]: string } = {
  pending: 'Pending',
  completed: 'Completed',
};

export enum APPLICATION_TYPE_FOR_DOCUMENTS {
  INDIVIDUAL = 'individual',
  NON_INDIVIDUAL = 'non_individual',
  INDIVIDUAL_POA = 'individual_poa',
  NRI = 'nri',
  NRI_POA = 'nri_poa',
  NOMINEE_DOC = 'nominee',
  NOMINEE_GUARDIAN = 'nomineeGuardian',
}
export const BankAccountTypeMaster: { [key: string]: string } = {
  Current: 'CURRENT',
  Savings: 'SAVINGS',
};
export const NRIBankAccountTypeMaster: { [key: string]: string } = {
  NRE: 'NRE',
  NRO: 'NRO',
};
export const DistributorTypesForUserManageMent: { [key: string]: string } = {
  Individual: 'individual',
  Company: 'company',
};

export const ENABLE_KRA_DOCUMENT_FETCH = false;

export const SALT = 'F2lNZWFxUH';
export const SALT2 = 'WTVPJel6dTGVocF';

export const ONBOARDING_OPTIONS: { [key: string]: string } = {
  // otp: 'Verify OTP',
  // email_consent: 'Email Consent',
  skip_otp: 'Skip Consent',
};

export const ACCOUNT_TYPE_OPTIONS: { [key: string]: string } = {
  discretionary: 'Discretionary',
  // non_discretionary: 'Non discretionary',
};

export const EmailConsentStatusMaster: { [key: string]: string } = {
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Approved',
  revoked: 'Revoked',
};

export const ENABLE_Email_Consent = false;

export const BloodRelations = ['Father', 'Mother', 'Son', 'Daughter', 'Spouse'];

export const FundInvestStockMarket: { [key: string]: string } = {
  own: 'Own Funds',
  borrowed: 'Borrowed funds',
};

export const ModeOfTransaction: { [key: string]: string } = {
  cheque: 'Cheque',
  rtgs: 'Online Transfer (RTGS)',
};

export const YesNoMaster: { [key: string]: string } = {
  yes: 'Yes',
  no: 'No',
};

export const PHOTOCAPTURE_STATUS: { [key: string]: string } = {
  pending: 'Pending',
  received: 'Completed',
};
export const AmcBranchMaster: { [key: string]: string } = {
  RETAIAHAMC: 'AHMEDABAD',
  AMCMOPEPMS: 'AMCMOPEPMS',
  OMONLAMCH: 'AMITKUMAR K CHOUDHARY-ONL',
  RMALESHAMC: 'BANGALORE',
  OFLTNAGAMC: 'CHENNAI',
  COCHINAMC: 'COCHINAMC',
  COIMBATAMC: 'COIMBATAMC',
  RJHANDEAMC: 'DELHI',
  RETAIHYAMC: 'HYDERABAD',
  OFLKOLAMC: 'KOLKATA',
  RMANGALAMC: 'Mangalore',
  MOAMCPMS: 'MOSL AMCPMS',
  MOTAMC: 'MOTAMC',
  RPUNAAMC: 'PUNE',
  RCOCHINAMC: 'RCOCHINAMC',
  RETLUCKAMC: 'RETLUCKAMC LUCKNOW',
  RINDOREAMC: 'RINDOREAMC',
  RJAIPURAMC: 'RJAIPURAMC',
  RBARODAAMC: 'VADODARA',
  RLUDHINAMC: 'RLUDHINAMC',
  RNASIKAMC: 'RNASIKAMC',
  RSURATTAMC: 'SURAT',
  RCHANDIAMC: 'Chandigarh',
};

export const branchMaster = Object.keys(AmcBranchMaster).map((value) => ({
  key: AmcBranchMaster[value],
  value: value,
}));

export const DistributorChannelMaster = [
  'Direct',
  'Wealth',
  'Branch',
  'PCG & HNI',
  'Omni',
  'Franchisee',
  'IFA',
  'EBG',
  'EWG',
  'Retail Branch',
  'Private Wealth',
  'Premia',
  'Banca',
  'Alternates',
  'La Premia',
  'ANG (Associate Group Network)',
  'PCG',
  'PCG Dealing Network',
  'Branch Dealing',
  'Dealing',
  'Retail',
  'NRI',
  'Burgandy',
  'Burgandy Private',
  'GPC',
  'Private Banking',
  'Bank',
  'Private',
  'Branch Banking',
  'Pioneer',
  'Privy',
  'Dubai',
  'MUBB',
  'Affluent',
]
  .sort((city1, city2) => city1.toString().localeCompare(city2.toString()))
  .map((value) => ({
    key: value,
    value: value,
  }));

export const RoleTypes: { [key: string]: string } = {
  amc_rm: 'AMC',
  support_rm: 'Support',
};

export const CompanyNameMasters = ['Company1', 'Company2']
  .sort((company1, company2) => company1.toString().localeCompare(company2.toString()))
  .map((value) => ({
    key: value,
    value: value,
  }));

export const strategyFeeTypeMaster: { [key: string]: string } = {
  flatOnAum: 'Flat on AUM',
  flat: 'Flat',
  none: 'none',
};

export const strategyPerformanceFeeTypeMaster: { [key: string]: string } = {
  with_catchup: 'With Catchup on Gains',
  without_catchup: 'Without Catchup on Gains',
  none: 'none',
};

export const strategyFeesMaster: { [key: string]: string } = {
  fixed: 'Fixed Management fees 2% custodian 1%',
  variable: 'Variable Management % customer 0.5%',
};
export const CHECKER_EDIT_ALL_SECTIONS = true;

export const SHOW_SECTION_FILLED_BY_INVESTOR_CHECKBOX = true;

export const FeeTypeMaster = [
  { label: 'Fixed', value: 'Fixed' },
  { label: 'Variable', value: 'Variable' },
  { label: 'Hybrid', value: 'Hybrid' },
  { label: 'Liquid', value: 'Liquid' },
];
export const COMMENT_ROLES: { [key: string]: string } = {
  rm: 'RM',
  distributor: 'Distributor',
  subdistributor: 'Sub Distributor',
  amcapprover: 'Checker',
};

export const CbosStatus: { [key: string]: string } = {
  null: 'Pending',
  false: 'Failed',
  true: 'Accepted',
};

export const APPLICATION_LISTING_STATUS_TO_DISPLAY_CBOS_STATUS: { [key: string]: string } = {
  draft: 'Draft',
  sent_to_poa_approver: 'Sent to POA approver',
  sent_to_amc_approver: 'Sent to Checker',
  sent_to_authorised_signatories: 'Sent to authorized signatories',
  sent_to_applicant1: 'Sent to applicant(s)',
};

export const projectIsProd = true;

export const nationalityMaster = ['Indian', 'Others'];

export const relatedPartyConsent = ['dissent', 'consent'];
export const APMIFlow = true; // this flow contains consent submit & reject options for joint holders, allowing investor to update Risk profile, disclosure of interest and disclosure of exclusion for their investment options, even though it is filled by maker

export const docInvalidCheck =
  'Please upload a file that meets the size and format requirements, and ensure it is not password protected.';
