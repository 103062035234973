/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Inform Beckend if any modifications done in this file
import * as yup from 'yup';
import {
  Bank,
  ControllingPersons,
  Groups,
  NomineeType,
  nonIndividualQuestionsFatca,
  ubo,
  Applicant,
  RMType,
} from '../redux-store/types/api-types';
import {
  accountNumberRegex,
  ifscRegex,
  stringRegex,
  addressRegex,
  phoneRegExp,
  nonIndividualPanRegex,
  emailRegex,
  individualPanRegex,
  numberRegex,
  amountRegex,
  alphaNumeric,
  indianPin,
  arnCodeRegex,
  defaultRegexWithSingleLine,
  kraStringRegex,
  ckycNumberRegex,
  kraMobileNumber,
  stringWithSpecialCharacterRegex,
} from './regex';
import {
  isMinor,
  currencyConversion,
  maxAge,
  getCountryCodes,
  checkForCorrespondenceAddress,
  futureAge,
  fieldValidateForInvestor,
  checkFeeTypeForHuddleRate,
  documentTypeValidationCheckForDocumentNumberDocumentExpiryDate,
  documentNameValidationCheckForDocumentExpiryDate,
} from './utilityFunctions';
import { InternalOptions } from 'yup/lib/types';
import { Values } from '../components/investors/contactDetails';
import { Distributor, SubDistributor } from '../redux-store/actions';
import { Values as ContributorValues } from '../components/investors/contributorDetails';
import { Values as DocumentValues } from '../components/investors/documentDetails';
import { Values as NonIndividualDocValues } from '../components/NonIndividualInvestor/DocumentDetails';
import {
  getSubQuestionsIndex,
  Values as NonIndividualFatcaValues,
} from '../components/NonIndividualInvestor/fatca';
import {
  addressTypesMasters,
  adminSupportMaster,
  AmcBranchMaster,
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  CompanyMasters,
  CpUboCodesMaster,
  debitingFreqMaster,
  DistributorChannelMaster,
  educationQualificationMasters,
  fatherOrSpouseSelectMasters,
  FeeTypeMaster,
  FundInvestStockMarket,
  genderMasters,
  grossAnnualMasters,
  investorTypeMasters,
  maritalStatusMasters,
  nationalitiesMasters,
  nationalityMaster,
  NomineeRelations,
  NRIBankAccountTypeMaster,
  occupationDetailsMasters,
  PEPsMasters,
  Relations,
  RoleTypes,
  salutationsMasters,
  SUPPORT_STATUS,
  YearEndMaster,
  YesNoMaster,
} from './constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Values as NonIndividualContactValues } from '../components/NonIndividualInvestor/ContactDetails';
import { Values as individualFatcaValues } from '../components/investors/fatca';
import { CustodianMaster } from './constant';
import { mdmsCompaniesList } from '../redux-store/types/mdms';

export const contributorDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable().required('Full Name is required'),
      // .matches(kraStringRegex, 'Special characters and numbers not allowed'),
      gender: yup
        .string()
        .nullable()
        .required('Please select gender')
        .oneOf(genderMasters, 'Invalid value for Gender'),
      motherName: yup
        .string()
        .nullable()
        .matches(stringRegex, 'Special characters and numbers not allowed')
        .required('Mother Name is required'),
      firstName: yup
        .string()
        .nullable()
        .matches(stringWithSpecialCharacterRegex, 'Special characters and numbers not allowed')
        .required('First Name is required'),
      lastName: yup
        .string()
        .nullable()
        .matches(stringWithSpecialCharacterRegex, 'Special characters and numbers not allowed')
        .required('Last Name is required'),
      fatherOrSpouseName: yup.string().nullable().required('Father/Spouse Name is required'),
      // .matches(kraStringRegex, 'Special characters and numbers not allowed'),
      dateOfBirth: yup
        .string()
        .nullable()
        .test(
          'dateOfBirth',
          'Age should be greater than 18',
          (dateOfBirth) => !isMinor(dateOfBirth || '')
        )
        .required('Date of Birth is required'),
      status: yup
        .string()
        .nullable()
        .oneOf(Object.keys(applicantStatusMasters), 'Invalid value for Status')
        .required('Status is required'),
      nationality: yup
        .string()
        .nullable()
        .test('nationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<ContributorValues>;
          const { nationalityDropdown = [] } = optionsContext || {};
          if (!fieldValue) {
            return createError({
              message: 'Nationality is required',
              path,
            });
          }
          if (!nationalityMaster.includes(fieldValue)) {
            return createError({
              message: 'Invalid value for Nationality',
              path,
            });
          }
          // if (!nationalityDropdown.includes(fieldValue)) {
          //   return createError({
          //     message: 'Invalid value for Nationality',
          //     path,
          //   });
          // }
          // if (
          //   applicantStatusMasters[parent.status] === applicantStatusMasters.NRI &&
          //   value?.toUpperCase() !== 'INDIAN'
          // ) {
          //   return createError({
          //     message: 'Invalid value for Nationality',
          //     path,
          //   });
          // }
          return true;
        }),
      jointApplicantRelation: yup
        .string()
        .nullable()
        .test('jointApplicationRelation', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path } = context;
          const { index } = options as InternalOptions<ContributorValues> & {
            index: number;
          };

          if (index !== 0) {
            if (!fieldValue) {
              return createError({
                message: 'Relationship with first applicant is required',
                path,
              });
            }
            if (fieldValue && !Object.keys(Relations).includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Relationship with first applicant',
                path,
              });
            }
          }
          return true;
        }),
      relationShipWithFirstApplicant: yup
        .string()
        .nullable()
        .when('jointApplicantRelation', {
          is: (jointApplicantRelation: string | null) =>
            Relations[jointApplicantRelation || ''] === Relations.others,
          then: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .required('Please Specify Relationship'),
        }),
      gstState: yup
        .string()
        .nullable()
        .matches(stringRegex, 'Special characters and numbers not allowed'),
      fatherOrSpouse: yup
        .string()
        .nullable()
        .required('Please Select Father/Spouse')
        .oneOf(Object.keys(fatherOrSpouseSelectMasters), 'Invalid value for Father or Spouse'),
      maritalStatus: yup
        .string()
        .nullable()
        .required('Please Select  Marital Status')
        .oneOf(Object.keys(maritalStatusMasters), 'Invalid value for Marital Status'),
      occupationType: yup
        .string()
        .nullable()
        .required('Please Select Occupation Details')
        .oneOf(Object.keys(occupationDetailsMasters), 'Invalid value for Occupation Type'),
      aadhaarCheck: yup
        .string()
        .nullable()
        .required('Please Select Provide Aadhaar')
        .oneOf(Object.values(YesNoMaster), 'Invalid value for Provide Aadhaar'),
      aadhaarNumber: yup
        .string()
        .nullable()
        .when('aadhaarCheck', {
          is: (aadhaarCheck: string | null, aadhaarNumber: string | null) =>
            aadhaarCheck === YesNoMaster.yes,
          then: yup
            .string()
            .nullable()
            .test('aadhaarNumber', (value, context) => {
              const { createError, path, parent } = context;
              if (value?.length !== 4) {
                return createError({
                  message: 'Please Specify Aadhaar Last 4 digits',
                  path,
                });
              }
              return true;
            })
            // .required('Please Specify Aadhaar Last 4 digits')
            .matches(numberRegex, 'Aadhaar Number allows only digits'),
        }),
      educationalQualification: yup
        .string()
        .nullable()
        .required('Please Select Education Qualification')
        .oneOf(
          Object.keys(educationQualificationMasters),
          'Invalid value for Education Qualification'
        ),
      taxAssesseeCheck: yup
        .string()
        .nullable()
        .test('taxAssesseeCheck', (value, context) => {
          const { createError, path } = context;
          if (value && !Object.values(YesNoMaster).includes(value)) {
            return createError({
              message: 'Invalid value for  Distributor Channel',
              path,
            });
          }
          return true;
        }),
      // .required('Please Select Tax Assessee'),
      occupationDetails: yup.string().when('occupationType', {
        is: (occupationType: string | null) =>
          occupationDetailsMasters[occupationType || ''] === occupationDetailsMasters.others,
        then: yup
          .string()
          .matches(stringRegex, 'Special characters and numbers not allowed')
          .required('Please Specify Occupation Type'),
      }),
      panNo: yup
        .string()
        .nullable()
        .uppercase()
        .matches(individualPanRegex, 'Only individual PANs are allowed'),
      gstNumber: yup
        .string()
        .nullable()
        .matches(alphaNumeric, 'Special Characters are Not allowed'),
    })
  ),
});

const validateOverseasAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? true : fieldValue !== '';
};

const validatePermanentAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  applicationType: string
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  const { permanentAddressSameAsCorresponding = false } =
    checkForCorrespondenceAddress(nationality, status) ||
    APPLICATION_TYPE.NON_INDIVIDUAL === applicationType
      ? currentApplicant.address.correspondence || {}
      : currentApplicant.address.overseas || {};
  return permanentAddressSameAsCorresponding ? true : fieldValue !== '';
};

const validatePincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  if (addressType === 'correspondence' && !checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (addressType === 'overseas' && checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (!fieldValue) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  return true;
};

const validateCorrespondenceAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? fieldValue !== '' : true;
};

export const contactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      phoneNumberoffice: yup
        .string()
        .nullable()
        .matches(numberRegex, 'Phone(Office) allows only digits'),
      phoneNumberResidence: yup
        .string()
        .nullable()
        .matches(numberRegex, 'Phone(Residential) allows only digits'),
      kraMobileNumber: yup
        .string()
        .nullable()
        .matches(kraMobileNumber, 'Invalid KRA Mobile Number'),
      kraEmail: yup.string().nullable(),
      // .matches(emailRegex, 'Invalid Email ID'),
      email: yup
        .string()
        .nullable()
        .matches(emailRegex, 'Invalid Email ID')
        .required('Email is required'),
      mobile: yup
        .string()
        .nullable()
        .test('mobile', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.countryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Mobile is required',
              path,
            });
          }
          if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          if (!isValidPhoneNumber(value, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      countryNameAndCode: yup
        .string()
        .nullable()
        .test('countryNameAndCode', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === value) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Country Code is required',
              path,
            });
          }
          if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            // .matches(
            //   addressRegex,
            //   "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            // )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          address2: yup
            .string()
            .nullable()
            // .matches(
            //   addressRegex,
            //   "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            // )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              if (!fetchedFromKRA) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          address3: yup.string().nullable(),
          // .matches(
          //   addressRegex,
          //   "Invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
          // ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validatePincodeField(value, context, 'correspondence')
            ),
          city: yup
            .string()
            .nullable()
            // .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('city', 'City is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          state: yup
            .string()
            .nullable()
            // .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('state', 'State is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          // country: yup
          // .string()
          // .nullable()
          // .test('country', 'Country is required', (value, context) =>
          // validateCorrespondenceAddressFields(value, context)
          // ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [], applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;

              if (!value && checkForCorrespondenceAddress(nationality, status)) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (
                value &&
                checkForCorrespondenceAddress(nationality, status) &&
                !countryDropdown.includes(value)
              ) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        overseas: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => validatePincodeField(value, context, 'overseas')),
          city: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('city', 'City is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          state: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed'),
          //   .test('state', 'State is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              if (!value && checkForCorrespondenceAddress(nationality, status)) {
                return true;
              }
              if (!value) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (value.toUpperCase() === 'INDIA') {
                return createError({
                  message: "Country can't be choosen as INDIA",
                  path,
                });
              }
              if (!countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            // .matches(
            //   addressRegex,
            //   "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            // )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          address2: yup
            .string()
            .nullable()
            // .matches(
            //   addressRegex,
            //   "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            // )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          address3: yup.string().nullable(),
          // .matches(
          //   addressRegex,
          //   "Invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
          // )
          pincode: yup
            .string()
            .trim()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                return validatePincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            // .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('city', 'City is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          state: yup
            .string()
            .nullable()
            // .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('state', 'State is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [] } = optionsContext || {};
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                // if (
                //   applicantStatusMasters[status || ''] === applicantStatusMasters.NRI &&
                //   value.toUpperCase() === 'INDIA'
                // ) {
                //   return createError({
                //     message:
                //       "If the Status is Individual (NRI) then Country can't be choosen as INDIA",
                //     path,
                //   });
                // }
                if (!countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validatePermanentAddressFields(value, context)
          //   ),
        }),
      }),
    })
  ),
});

export const KYCDetailsSchema = yup.object().shape({
  ownBorrowedFunds: yup
    .string()
    .nullable()
    .required('Fund Type is Required')
    .oneOf(Object.keys(FundInvestStockMarket), 'Invalid Fund Type'),
  applicants: yup.array().of(
    yup.object().shape({
      politicallyExposedPersonStatus: yup
        .string()
        .nullable()
        .required('PEP status is required')
        .oneOf(PEPsMasters, 'Invalid value for PEP Status'),
      ckycNo: yup.number().test('ckycNo', (value, context) => {
        const {
          options: { context: optionsContext },
          createError,
          path,
        } = context;
        const { applicants } = optionsContext || {};
        if (!value) {
          return true;
        }
        if (!value.toString().match(ckycNumberRegex)) {
          return createError({
            message: 'Invalid CKYC No',
            path,
          });
        }
        if (
          applicants.filter(
            (applicant: Applicant) => applicant.ckycNo?.toString() === value.toString()
          ).length !== 1
        ) {
          return createError({
            message:
              'There is already same CKYC Number for an applicant associated with this application',
            path,
          });
        }
        return true;
      }),
      grossAnnualIncome: yup
        .string()
        .nullable()
        .test('grossAnnualIncome', (value, context) => {
          const { options, createError, path } = context;
          const { context: optionsContext, index } = options as InternalOptions<Values> & {
            index: number;
          };
          if (
            !value
            // && index < 2
          ) {
            return createError({
              message: 'Gross Annual Income is Required',
              path,
            });
          }
          if (value && !Object.keys(grossAnnualMasters).includes(value)) {
            return createError({
              message: 'Invalid value for Gross Annual Income',
              path,
            });
          }
          return true;
        }),
      grossAnnualIncomeDate: yup
        .string()
        .nullable()
        .test('grossAnnualIncomeDate', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, options, createError, parent } = context;
          const { context: optionsContext, index } = options as InternalOptions<Values> & {
            index: number;
          };
          if (
            !value
            // && index < 2
          ) {
            return createError({
              message: 'Gross Annual Income as on Date Required',
              path,
            });
          }
          if (futureAge(fieldValue || '')) {
            return createError({
              message: 'Invalid Date',
              path,
            });
          }
          return true;
        }),
      // grossAnnualIncome: yup.string().required('Gross Annual Income is Required'),
      netWorth: yup
        .string()
        .required('Networth is Required')
        .matches(amountRegex, 'Invalid Networth'),
      netWorthDate: yup
        .string()
        .nullable()
        .test('netWorthDate', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (!fieldValue) {
            return createError({
              message: 'Networth as on Date Required',
              path,
            });
          }
          if (futureAge(fieldValue || '')) {
            return createError({
              message: 'Invalid Date',
              path,
            });
          }
          return true;
        }),
    })
  ),
});

const createSchemaForNonIndianResident = (validationMessage: string) => {
  return {
    is: (value: string) => value.toString() === 'true',
    then: yup.string().required(validationMessage),
  };
};

export const FATCAValidationSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      typeOfAddressProvidedAtKRA: yup
        .string()
        .required('Please select type of address provided')
        .oneOf(addressTypesMasters, 'Invalid value for Type of Address Provided At KRA'),
      placeOfBirth: yup
        .string()
        .required('Please enter place of birth')
        .matches(stringRegex, 'Special characters and numbers not allowed'),
      // countryOfBirth: yup
      //   .string()
      //   .required('Please enter country of birth')
      //   .matches(stringRegex, 'Please enter country of birth'),
      countryOfBirth: yup
        .string()
        .nullable()
        .test('countryOfBirth', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!fieldValue) {
            return createError({
              message: 'country of birth is required',
              path,
            });
          }
          if (!countryDropdown.includes(fieldValue)) {
            return createError({
              message: 'Invalid value for country of birth',
              path,
            });
          }
          return true;
        }),
      // countryOfNationality: yup
      //   .string()
      //   .required('Please enter country of nationality')
      //   .matches(stringRegex, 'Please enter country of nationality'),
      countryOfNationality: yup
        .string()
        .nullable()
        .test('countryOfNationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!fieldValue) {
            return createError({
              message: 'country of nationality is required',
              path,
            });
          }
          if (!countryDropdown.includes(fieldValue)) {
            return createError({
              message: 'Invalid value for country of nationality',
              path,
            });
          }
          return true;
        }),
      taxCountryName: yup
        .string()
        .nullable()
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('Please enter tax country name')
        )
        .test('taxCountryName', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (
            fieldValue &&
            parent.taxResidentOfAnyCountryOtherThanIndia === 'true' &&
            !countryDropdown.includes(fieldValue)
          ) {
            return createError({
              message: 'Invalid value for tax country',
              path,
            });
          }
          return true;
        }),
      taxID: yup
        .string()
        .nullable()
        .max(20, 'Invalid Tax ID')
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('Please enter Tax ID')
        )
        .test('taxID', (value, context) => {
          const { createError, path, parent } = context;
          if (
            value &&
            parent.taxResidentOfAnyCountryOtherThanIndia === 'true' &&
            !value.match(alphaNumeric)
          ) {
            return createError({
              message: 'Special Characters are Not allowed',
              path,
            });
          }
          return true;
        }),
      idType: yup
        .string()
        .nullable()
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('Please enter ID Type')
        )
        .test('idType', (value, context) => {
          const { createError, path, parent } = context;
          if (
            value &&
            parent.taxResidentOfAnyCountryOtherThanIndia === 'true' &&
            !value.match(stringRegex)
          ) {
            return createError({
              message: 'Special characters and numbers not allowed',
              path,
            });
          }
          return true;
        }),
      taxResidentOfAnyCountryOtherThanIndia: yup
        .string()
        .nullable()
        .required('Tax Resident of any country other then india is required')
        .oneOf(['true', 'false'], 'Invalid Tax Resident of any country other then india'),
      // nameOfEntity: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter name of entity')
      //   ),
      // dateOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter date of incorporation')
      //   ),
      // cityOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter city of incorporation')
      //   ),
      // countryOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter country of incorporation')
      //   ),
      // entityExcemptionCode: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter entity exemption code')
      //   ),
    })
  ),
});

export const adminSupportValidation = (supportOptions: string[]) =>
  yup.object().shape({
    supportType: yup
      .string()
      .required('Support Type is required')
      .oneOf(supportOptions, 'Invalid Support Type'),
    application_number: yup
      .string()
      .nullable()
      .when('supportType', {
        is: (supportType: string) =>
          [
            adminSupportMaster.change_expired_status,
            adminSupportMaster.change_status_to_draft,
          ].includes(adminSupportMaster[supportType]),
        then: yup
          .string()
          .matches(defaultRegexWithSingleLine, 'Invalid Application Number')
          .required('Application Number is required'),
      }),
    status: yup
      .string()
      .nullable()
      .when('supportType', {
        is: (supportType: string) =>
          adminSupportMaster[supportType] === adminSupportMaster.change_expired_status,
        then: yup
          .string()
          .required('Status is required')
          .oneOf(Object.keys(SUPPORT_STATUS), 'Invalid Status'),
      }),
    phone: yup
      .string()
      .nullable()
      .when('supportType', {
        is: (supportType: string) =>
          ![
            adminSupportMaster.change_expired_status,
            adminSupportMaster.change_status_to_draft,
          ].includes(adminSupportMaster[supportType]),
        then: yup.string().test('phone', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.countryCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Mobile number is required',
              path,
            });
          }
          if (parent.countryCode === 'India: +91' && !phoneRegExp.test(value)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          if (!isValidPhoneNumber(value, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          return true;
        }),
      }),
    // .required('Mobile Number is required')
    // .min(8, 'Invalid mobile number')
    // .max(16, 'Invalid mobile number')
    // .matches(phoneRegExp, 'Invalid mobile number'),
    // countryCode: yup
    //   .string()
    //   .nullable()
    //   .test('countryCode', (value, context) => {
    //     const { createError, path, parent } = context;
    //     const codesList = getCountryCodes()
    //       .map((codes) => {
    //         if (codes.label === value) {
    //           return codes.countryCode;
    //         }
    //       })
    //       .filter((ele) => ele)
    //       .toString();
    //     if (!value) {
    //       return createError({
    //         message: 'Country Code is required',
    //         path,
    //       });
    //     }
    //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
    //       return createError({
    //         message: 'Invalid Mobile number',
    //         path,
    //       });
    //     }
    //     return true;
    //   }),
  });

export const nomineeDetailsSchema = (applicants?: Partial<Applicant>[], finalForm?: boolean) =>
  yup.object().shape({
    nominees: yup.array().of(
      yup.object().shape({
        // nomineeName: yup
        //   .string()
        //   .nullable()
        //   .when('isActive', {
        //     is: (isActive: boolean) => isActive,
        //     then: yup
        //       .string()
        //       .nullable()
        //       .matches(stringRegex, 'Special characters and numbers not allowed')
        //       .required('Nominee Name is required'),
        //   }),
        firstName: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Nominee First Name is required'),
          }),
        lastName: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Nominee Last Name is required'),
          }),
        nomineeRelationship: yup.string().when(['Relationship', 'isActive'], {
          is: (Relationship: string | null, isActive: boolean) =>
            isActive && NomineeRelations[Relationship || ''] === NomineeRelations.others,
          then: yup
            .string()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .required('Please Specify Relationship'),
        }),
        Relationship: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .oneOf(Object.keys(NomineeRelations), 'Invalid value for Nominee Relationship')
              .required('Please select relationship'),
          }),
        dateOfBirth: yup
          .string()
          .nullable()
          .test('dateOfBirth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.isActive) {
              return createError({
                message: 'Date of Birth is required',
                path,
              });
            }
            if (futureAge(fieldValue || '') && parent.isActive) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (maxAge(fieldValue || '') && parent.isActive) {
              return createError({
                message: 'Age should be less than 125',
                path,
              });
            }
            return true;
          }),
        nomineePercentage: yup.number().when('isActive', {
          is: (isActive: boolean) => isActive,
          then: yup
            .number()
            .required('Nominee % is required')
            .typeError('Nominee % should be a number')
            .test(
              'nomineePercentage',
              'Total Nominee % should be equal to 100%',
              (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                } = context;
                if (value === 0) {
                  return createError({
                    message: 'Nominee % should be greater then 0',
                    path,
                  });
                }
                const { nominees } = optionsContext || {};
                const total = nominees
                  .filter((_nominee: NomineeType) => _nominee.isActive)
                  .map((nominee: NomineeType) => Number(nominee.nomineePercentage))
                  .reduce((a: number, b: number) => a + b, 0);
                return nominees.length && total === 100;
              }
            ),
        }),
        // guardianName: yup
        //   .string()
        //   .nullable()
        //   .when(['dateOfBirth', 'isActive'], {
        //     is: (dateOfBirth: string | null, isActive: boolean) =>
        //       isActive && isMinor(dateOfBirth || ''),
        //     then: yup
        //       .string()
        //       .nullable()
        //       .matches(stringRegex, 'Special characters and numbers not allowed')
        //       .required('Guardian Name is required'),
        //   })
        //   .test('guardianName', 'Invalid Guardian Name', (value, context) => {
        //     const { parent } = context;
        //     return value && finalForm && !isMinor(parent.dateOfBirth) ? false : true;
        //   }),
        guardianFirstName: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Guardian First Name is required'),
          })
          .test('guardianFirstName', 'Invalid Guardian First Name', (value, context) => {
            const { parent } = context;
            return value && finalForm && !isMinor(parent.dateOfBirth) ? false : true;
          }),
        guardianLastName: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Guardian Last Name is required'),
          })
          .test('guardianLastName', 'Invalid Guardian Last Name', (value, context) => {
            const { parent } = context;
            return value && finalForm && !isMinor(parent.dateOfBirth) ? false : true;
          }),
        guardianDOB: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup.string().nullable().required('Guardian Date of Birth is required'),
          })
          .test('guardianDOB', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (futureAge(fieldValue || '') && parent.isActive) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (isMinor(fieldValue || '') && parent.isActive) {
              return createError({
                message: 'Age should be greater than 18',
                path,
              });
            }
            if (maxAge(fieldValue || '') && parent.isActive) {
              return createError({
                message: 'Age should be less than 125',
                path,
              });
            }
            return true;
          })
          .test('guardianDOB', 'Invalid Guardian Date of Birth', (value, context) => {
            const { parent } = context;
            return value && finalForm && !isMinor(parent.dateOfBirth) ? false : true;
          }),
        // nomineeAddress: yup
        //   .string()
        //   .nullable()
        //   .when('isActive', {
        //     is: (isActive: boolean) => isActive,
        //     then: yup
        //       .string()
        //       .nullable()
        //       .matches(addressRegex, "special characters are not allowed except -/',&()#:."),
        //   }),

        nomineeAddress1: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid nominee address line 1 format [special characters are not allowed except -/',&()#:.]"
              )
              .required('Nominee Address line 1 is required'),
          }),
        nomineeAddress2: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid nominee address line 2 format [special characters are not allowed except -/',&()#:.]"
              )
              .required('Nominee Address line 2 is required'),
          }),
        nomineeAddress3: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid nominee address line 3 format [special characters are not allowed except -/',&()#:.]"
              )
              .required('Nominee Address line 3 is required'),
          }),
        nomineeCity: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Nominee City is required'),
          }),
        nomineeState: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Nominee State is required'),
          }),
        nomineePincode: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .test('nomineePincode', (value, context) => {
                const fieldValue = value === undefined ? '' : value;
                const { options, path, createError, parent } = context;

                if (!fieldValue) {
                  return createError({
                    message: 'Nominee Pincode is required',
                    path,
                  });
                }
                if (parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
                  return createError({
                    message: 'Nominee Pincode should be 6 digit code',
                    path,
                  });
                }
                if (parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
                  return createError({
                    message: 'Invalid Nominee Pincode',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeCountry: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .test('nomineeCountry', (value, context) => {
                const { options, createError, path } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { countryDropdown = [], applicants = [] } = optionsContext || {};
                if (!value) {
                  return createError({
                    message: 'Nominee Country is required',
                    path,
                  });
                }

                if (value && !countryDropdown.includes(value)) {
                  console.log(countryDropdown, value);
                  return createError({
                    message: 'Invalid value for Nominee Country',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineePan: yup
          .string()
          .nullable()
          // .matches(individualPanRegex || /^[0-9]{12}$/, 'Only individual PANs/Aadhaar are allowed')
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .test('nomineePan', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const { nominees = [] } = optionsContext || {};
                const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
                const applicantsAadhar =
                  applicants?.map((applicant) => applicant.aadhaarNumber) || [];
                if (!value) {
                  return createError({
                    message: 'Nominee PAN/ Aadhaar is required',
                    path,
                  });
                }
                if (!(value.match(individualPanRegex) || value.match(/^[0-9]{12}$/))) {
                  return createError({
                    message: 'Only individual PAN/ Aadhaar are allowed',
                    path,
                  });
                }

                // if (!value && !isMinor(parent.dateOfBirth || '')) {
                //   return createError({
                //     message: 'Nominee PAN is required',
                //     path,
                //   });
                // }
                if (value && applicantsPan.length && applicantsPan?.includes(value)) {
                  return createError({
                    message: 'Applicant PAN not allowed to enter as nominee PAN',
                    path,
                  });
                }
                if (
                  value &&
                  applicantsAadhar.length &&
                  applicantsAadhar?.includes(value.substring(value.length - 4))
                ) {
                  return createError({
                    message: 'Applicant aadhar not allowed to enter as nominee Aadhar',
                    path,
                  });
                }
                if (
                  value &&
                  nominees.filter((nominee: NomineeType) => nominee.nomineePan === value).length !==
                    1
                ) {
                  return createError({
                    message:
                      'There is already same pan for a nominee associated with this application',
                    path,
                  });
                }
                if (
                  value &&
                  parent.nomineeGuardianPan &&
                  value === parent.nomineeGuardianPan &&
                  isMinor(parent.dateOfBirth || '')
                ) {
                  return createError({
                    message: 'Nominee Pan should not be same as guardian PAN',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeGuardianPan: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(individualPanRegex, 'Only individual PANs are allowed')
              .test('nomineeGuardianPan', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
                if (!value && isMinor(parent.dateOfBirth || '')) {
                  return createError({
                    message: 'Guardian Pan is required',
                    path,
                  });
                }
                if (value && applicantsPan.length && applicantsPan?.includes(value)) {
                  return createError({
                    message: 'Applicant PAN not allowed to enter as Guardian PAN',
                    path,
                  });
                }
                if (value && parent.nomineePan && value === parent.nomineePan) {
                  return createError({
                    message: 'Guardian Pan should not be same as Nominee PAN',
                    path,
                  });
                }
                return true;
              }),
          })
          .test('nomineeGuardianPan', 'Invalid Nominee Guardian PAN', (value, context) => {
            const { parent } = context;
            return value && finalForm && !isMinor(parent.dateOfBirth) ? false : true;
          }),
        guardianRelationship: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .required('Please select Guardian Relationship')
              .oneOf(Object.keys(NomineeRelations), 'Invalid value for Guardian Relationship'),
          })
          .test(
            'guardianRelationship',
            'Invalid value for Guardian Relationship',
            (value, context) => {
              const { parent } = context;
              return value && finalForm && !isMinor(parent.dateOfBirth) ? false : true;
            }
          ),
        otherGuardianRelationship: yup
          .string()
          .nullable()
          .when(['guardianRelationship', 'isActive', 'dateOfBirth'], {
            is: (guardianRelationship: string | null, isActive: boolean, dateOfBirth: string) =>
              isActive &&
              isMinor(dateOfBirth || '') &&
              NomineeRelations[guardianRelationship || ''] === NomineeRelations.others,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Please Specify Guardian Relationship'),
          })
          .test('otherGuardianRelationship', 'Invalid Guardian Relationship', (value, context) => {
            const { parent } = context;
            return value && finalForm && !isMinor(parent.dateOfBirth) ? false : true;
          }),
        guardianAddress1: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid guardian address line 1 format [special characters are not allowed except -/',&()#:.]"
              )
              .required('Guardian Address line 1 is required'),
          }),
        guardianAddress2: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid guardian address line 2 format [special characters are not allowed except -/',&()#:.]"
              )
              .required('Guardian Address line 2 is required'),
          }),
        guardianAddress3: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(
                addressRegex,
                "Invalid guardian address line 3 format [special characters are not allowed except -/',&()#:.]"
              )
              .required('Guardian Address line 3 is required'),
          }),
        guardianCity: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Guardian City is required'),
          }),
        guardianState: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Guardian State is required'),
          }),
        guardianPincode: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .test('guardianPincode', (value, context) => {
                const fieldValue = value === undefined ? '' : value;
                const { path, createError, parent } = context;
                if (!fieldValue) {
                  return createError({
                    message: 'Guardian Pincode is required',
                    path,
                  });
                }
                if (parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
                  return createError({
                    message: 'Guardian Pincode should be 6 digit code',
                    path,
                  });
                }
                if (parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
                  return createError({
                    message: 'Invalid Guardian Pincode',
                    path,
                  });
                }
                return true;
              }),
          }),
        guardianCountry: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .test('guardianCountry', (value, context) => {
                const { options, createError, path } = context;
                const { context: optionsContext, index } = options as InternalOptions<Values> & {
                  index: number;
                };
                const { countryDropdown = [], applicants = [] } = optionsContext || {};
                if (!value) {
                  return createError({
                    message: 'Guardian Country is required',
                    path,
                  });
                }
                if (value && !countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Guardian Country',
                    path,
                  });
                }
                return true;
              }),
          }),
      })
    ),
  });

export const bankDetailsSchema = (applicants: Partial<Applicant>[] | undefined) =>
  yup.object().shape({
    banks: yup.array().of(
      yup.object().shape({
        ifscCode: yup
          .string()
          .nullable()
          .required('IFSC Code is required')
          .matches(ifscRegex, 'Invalid IFSC Code'),
        bankAccountNumber: yup
          .string()
          .nullable()
          .required('Bank Account Number is required')
          .matches(accountNumberRegex, 'Please enter valid Account Number')
          .test('bankAccountNumber', 'Account number already used', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            return banks.filter((bank: Bank) => bank.bankAccountNumber === value).length === 1;
          }),
        bankAccountType: yup
          .string()
          .nullable()
          // .required('Bank Account Type is required')
          .test('bankAccountType', 'Bank Account Type is required', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            const fieldValue = value === null ? '' : value;
            const { applicationType, status, banks } = optionsContext || {};
            if (
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
              !['huf'].includes(applicants ? applicants[0]?.investorType || '' : '') &&
              !fieldValue
            ) {
              return true;
            }
            if (
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
              fieldValue &&
              ![BankAccountTypeMaster.Current, BankAccountTypeMaster.Savings].includes(fieldValue)
            ) {
              return false;
            } else if (
              fieldValue &&
              applicantStatusMasters[status] !== applicantStatusMasters.NRI &&
              !Object.values(BankAccountTypeMaster).includes(fieldValue)
            ) {
              return false;
            }
            if (
              fieldValue &&
              applicantStatusMasters[status] === applicantStatusMasters.NRI &&
              !Object.values(NRIBankAccountTypeMaster).includes(fieldValue)
            ) {
              return false;
            }
            if (
              fieldValue &&
              applicantStatusMasters[status] === applicantStatusMasters.NRI &&
              banks.filter(
                (bank: Bank) =>
                  Object.values(NRIBankAccountTypeMaster).includes(bank.bankAccountType) &&
                  Object.values(NRIBankAccountTypeMaster).includes(fieldValue) &&
                  bank.bankAccountType != fieldValue
              ).length === 1
            ) {
              return createError({
                message: 'Both Bank Account Type should be same',
                path,
              });
            }

            return !!fieldValue;
          }),
        bankName: yup.string().nullable().required('Bank Name is required'),
        // .matches(stringRegex, 'Special characters and numbers not allowed'),
        bankBranch: yup.string().nullable().required('Bank Branch is required'),
        // .matches(stringRegex, 'Special characters and numbers not allowed'),
        defaultBankAccount: yup
          .bool()
          .test('defaultBankAccount', 'Please check the default bank account', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            const isDefaultBankSelected = banks.some((bank: Bank) => bank.defaultBankAccount);
            return isDefaultBankSelected;
          }),
        bankAddress: yup.string().nullable(),
        // .matches(
        //   addressRegex,
        //   "Invalid bank address format [special characters are not allowed except -/',&()#:.]"
        // ),
        micrCode: yup.string().nullable().matches(numberRegex, 'MICR code allows only digits'),
      })
    ),
  });

export const investmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number
) =>
  yup.object().shape({
    schemeId: yup.string().required('Fund Scheme is required'),
    planId: yup.string().required('Class Plan is required'),
    commitmentAmount: yup
      .number()
      .nullable()
      .min(
        minCommitmentAmount,
        `Commitment Amount should be greater than or equal to ${currencyConversion(
          minCommitmentAmount
        )}`
      )
      .max(
        maxCommitmentAmount,
        `Commitment Amount should be between ${
          currencyConversion(minCommitmentAmount) || '1 Cr'
        } and ${currencyConversion(maxCommitmentAmount) || '2 Cr'}`
      )
      .required('Commitment Amount is required'),
    setupFeePercentage: yup
      .number()
      .nullable()
      .required('Setup Fee Percentage is required')
      .lessThan(101, 'Setup Fee Percentage should be less then 100%'),
    contributionChequeAmount: yup
      .number()
      .nullable()
      .required('Contribution Cheque Amount is required')
      .max(
        yup.ref('commitmentAmount'),
        'Contribution Amount should not be greater than commitment amount'
      )
      .test(
        'contributionChequeAmount',
        `${
          Number(minContributionPercentage) === 100
            ? `Contribution Amount should be equal to commitment amount`
            : `Contribution Amount should be greater than or equal to ${minContributionPercentage}% of commitment amount`
        }`,
        (value, context) => {
          const { parent } = context;
          const { commitmentAmount } = parent;
          return (
            (100 * Number(value)) / Number(commitmentAmount) >= Number(minContributionPercentage)
          );
        }
      ),
    setupFeeTDS: yup
      .number()
      .nullable()
      .min(0, `TDS should be greater than 0`)
      .max(yup.ref('totalSetupFee'), 'TDS should not be greater than Total Setup Fee'),
  });

export const typeDeclarationScheme = (text: string) =>
  yup.object().shape({
    typedDeclaration: yup
      .string()
      .required('Fee Declaration is Required')
      .oneOf([text, null], "Fee Declaration doesn't match with sample!"),
  });

export const authorizedSignatoriesDocumentScheme = yup.object().shape({
  groupsignatorydocuments: yup.array().of(
    yup.object().shape({
      documentId: yup
        .string()
        .nullable()
        .test('documentId', (value, context) => {
          const {
            options: { context: optionsContext },
            createError,
            path,
            parent,
          } = context;
          const { required, documentName, documentType } = parent || {};
          if (required === 'false') {
            return true;
          }
          if (!value && required === 'true') {
            return createError({
              message: `Please upload ${documentName}`,
              path,
            });
          }

          return true;
        }),
    })
  ),
});

export const strategyDeclarationScheme = yup.object().shape(
  {
    applicationstrategydetails: yup.array().of(
      yup.object().shape({
        // strategyFees: yup
        //   .string()
        //   .nullable()
        //   .when('isActive', {
        //     is: (isActive: boolean) => isActive,
        //     then: yup.string().nullable().required('Fee is Required'),
        //   }),
        // managementFeeNature: yup
        //   .string()
        //   .nullable()
        //   .when('isActive', {
        //     is: (isActive: boolean) => isActive,
        //     then: yup.string().nullable().required('Management Fee Nature is Required'),
        //   }),
        // fee: yup
        //   .number()
        //   .nullable()
        //   .when('isActive', {
        //     is: (isActive: boolean) => isActive,
        //     then: yup
        //       .number()
        //       .nullable()
        //       .test('fee', (value, context) => {
        //         const {
        //           options: { context: optionsContext },
        //           createError,
        //           path,
        //           parent,
        //         } = context;
        //         if (!value && value !== 0) {
        //           return createError({
        //             message: 'Management Fee is Required',
        //             path,
        //           });
        //         }
        //         // if (value && Number(value) > Number(parent.maxFee)) {
        //         //   return createError({
        //         //     message: `Management Fee should be less than or equal to ${parent.maxFee}`,
        //         //     path,
        //         //   });
        //         // }
        //         // if (value >= 0 && Number(value) < Number(parent.minFee)) {
        //         //   return createError({
        //         //     message: `Management Fee should be greater than or equal to ${parent.minFee}`,
        //         //     path,
        //         //   });
        //         // }
        //         // if (
        //         //   value &&
        //         //   strategyFeeTypeMaster[parent.managementFeeNature] ===
        //         //     strategyFeeTypeMaster.flatOnAum &&
        //         //   Number(value) > 100
        //         // ) {
        //         //   return createError({
        //         //     message: `Management Fee percentage should be less then or equal to 100%`,
        //         //     path,
        //         //   });
        //         // }
        //         return true;
        //       }),
        //   }),
        //     performanceFeeNature: yup
        //       .string()
        //       .nullable()
        //       .when('isActive', {
        //         is: (isActive: boolean) => isActive,
        //         then: yup.string().nullable().required('Performance Fee Nature is Required'),
        //       }),
        //     performanceFee: yup
        //       .number()
        //       .nullable()
        //       .when('isActive', {
        //         is: (isActive: boolean) => isActive,
        //         then: yup
        //           .number()
        //           .nullable()
        //           .test('performanceFee', (value, context) => {
        //             const {
        //               options: { context: optionsContext },
        //               createError,
        //               path,
        //               parent,
        //             } = context;
        //             if (!value && value !== 0) {
        //               return createError({
        //                 message: 'Performance Fee is Required',
        //                 path,
        //               });
        //             }
        //             if (value && Number(value) > Number(parent.performanceMaxFee)) {
        //               return createError({
        //                 message: `Performance Fee should be less than or equal to ${parent.performanceMaxFee}%`,
        //                 path,
        //               });
        //             }
        //             if (value >= 0 && Number(value) < Number(parent.performanceMinFee)) {
        //               return createError({
        //                 message: `Performance Fee should be greater than or equal to ${parent.performanceMinFee}%`,
        //                 path,
        //               });
        //             }
        //             // if (
        //             //   value &&
        //             //   strategyFeeTypeMaster[parent.performanceFeeNature] ===
        //             //     strategyFeeTypeMaster.flatOnAum &&
        //             //   Number(value) > 100
        //             // ) {
        //             //   return createError({
        //             //     message: `Performance Fee percentage should be less then or equal to 100%`,
        //             //     path,
        //             //   });
        //             // }
        //             return true;
        //           }),
        //       }),
        //     exitFee: yup
        //       .number()
        //       .nullable()
        //       .when('isActive', {
        //         is: (isActive: boolean) => isActive,
        //         then: yup
        //           .number()
        //           .nullable()
        //           .test('exitFee', (value, context) => {
        //             const {
        //               options: { context: optionsContext },
        //               createError,
        //               path,
        //               parent,
        //             } = context;
        //             if (!value && value !== 0) {
        //               return createError({
        //                 message: 'Exit Fee is Required',
        //                 path,
        //               });
        //             }
        //             if (value && Number(value) > Number(parent.exitMaxFee)) {
        //               return createError({
        //                 message: `Exit Fee should be less than or equal to ${parent.exitMaxFee}`,
        //                 path,
        //               });
        //             }
        //             if (value >= 0 && Number(value) < Number(parent.exitMinFee)) {
        //               return createError({
        //                 message: `Exit Fee should be greater than or equal to ${parent.exitMinFee}`,
        //                 path,
        //               });
        //             }
        //             return true;
        //           }),
        //       }),
        //     investmentAmount: yup
        //       .number()
        //       .nullable()
        //       .when('isActive', {
        //         is: (isActive: boolean) => isActive,
        //         then: yup
        //           .number()
        //           .nullable()
        //           .required('investment amount is Required')
        //           .moreThan(0, 'Investment Amount cannot be zero'),
        //       }),
      })
    ),
    // totalStrategyInvestmentAmount: yup.number().nullable()
    // .required('Investment Amount is required')
    // .min(5000000, 'Total Investment Amount (in INR) must be greater than or equal to 50 lakhs'),
    // ownBorrowedFunds: yup.string().nullable().required('Fund Type is Required'),
    // cheque: yup.boolean().when('rtgs', {
    //   is: (rtgs: boolean) => !rtgs,
    //   then: yup.boolean().oneOf([true], 'At least one mode of transaction needs to be selected'),
    // }),
    // rtgs: yup.boolean().when('cheque', {
    //   is: (cheque: boolean) => !cheque,
    //   then: yup.boolean().oneOf([true], ''),
    // }),
  }
  // [['cheque', 'rtgs']]
);

export const riskProfileScheme = yup.object().shape({
  riskprofiles: yup.array().of(
    yup.object().shape({
      answer: yup.string().nullable().required('Field is required'),
      otherValue: yup
        .string()
        .nullable()
        .when('answer', {
          is: (answer: string) => answer === 'others',
          then: yup.string().nullable().required('Field is required'),
        }),
    })
  ),
});

export const relatedPartyConsentSchema = yup.object().shape({
  relatedpartyconsents: yup.array().of(
    yup.object().shape({
      singleAssociateLimit: yup
        .number()
        .nullable()
        .required('Setup fee required')
        // .min(1, 'Setup fee percentage Should be greater than or equal to 1%')
        // .when('securityType', {
        //   is: (securityType: string) => securityType !== 'Equity + Debt + Hybrid Securities',
        //   then: yup
        //     .number()
        //     .nullable()
        //     .max(15, 'Setup fee percentage Should be less than or equal to 15%'),
        // })
        // .when('securityType', {
        //   is: (securityType: string) => securityType === 'Equity + Debt + Hybrid Securities',
        //   then: yup
        //     .number()
        //     .nullable()
        //     .max(30, 'Setup fee percentage Should be less than or equal to 30%'),
        // })
        .test('singleAssociateLimit', (value, context) => {
          const {
            options: { context: optionsContext },
            createError,
            path,
            parent,
          } = context;
          if (
            (value || value === 0) &&
            (value > (parent.securityType === 'Equity + Debt + Hybrid Securities' ? 30 : 15) ||
              value < 1)
          ) {
            return createError({
              message: `Limit value is incorrect and cannot exceed ${
                parent.securityType === 'Equity + Debt + Hybrid Securities' ? 30 : 15
              }%`,
              path,
            });
          }

          return true;
        }),
      multipleAssociateLimit: yup
        .number()
        .nullable()
        .test('multipleAssociateLimit', (value, context) => {
          const {
            options: { context: optionsContext },
            createError,
            path,
            parent,
          } = context;
          if (!value && value !== 0 && parent.securityType !== 'Equity + Debt + Hybrid Securities')
            return createError({
              message: `Percentage required`,
              path,
            });
          if (
            parent.securityType !== 'Equity + Debt + Hybrid Securities' &&
            value &&
            (value > 25 || value < 1)
          ) {
            return createError({
              message: `Limit value is incorrect and cannot exceed 25%`,
              path,
            });
          }
          return true;
        }),
      // .when('securityType', {
      //   is: (securityType: string) => securityType !== 'Equity + Debt + Hybrid Securities',
      //   then: yup
      //     .number()
      //     .nullable()
      //     .required('Percentage required')
      //     .min(1, 'percentage Should be greater than or equal to 1%')
      //     .max(25, 'percentage Should be less than or equal to 25%'),
      // }),
    })
  ),
});

export const sourceOfInvestmentSchema = yup.object().shape(
  {
    totalStrategyInvestmentAmount: yup
      .number()
      .nullable()
      .required('Investment Amount is required'),
    // ownBorrowedFunds: yup.string().nullable().required('Fund Type is Required'),
    // cheque: yup.boolean().when('rtgs', {
    //   is: (rtgs: boolean) => !rtgs,
    //   then: yup.boolean().oneOf([true], 'At least one mode of transaction needs to be selected'),
    // }),
    // rtgs: yup.boolean().when('cheque', {
    //   is: (cheque: boolean) => !cheque,
    //   then: yup.boolean().oneOf([true], ''),
    // }),
  }
  // [['cheque', 'rtgs']]
);

export const disclosureOfInterestSchema = (
  referenceApplicantType: string,
  mdmsCompanyList: mdmsCompaniesList[],
  validateFormIncludingAllJointHoldersInInvestorLogin?: boolean
) =>
  yup.object().shape({
    interestedCompaniesCheck: yup
      .string()
      .nullable()
      .when('isDisclosureOfInterestToBeFilledByInvestor', {
        is: (isDisclosureOfInterestToBeFilledByInvestor: boolean) =>
          !isDisclosureOfInterestToBeFilledByInvestor ||
          (isDisclosureOfInterestToBeFilledByInvestor &&
            (fieldValidateForInvestor(referenceApplicantType, 1, false) ||
              validateFormIncludingAllJointHoldersInInvestorLogin)),
        then: yup.string().nullable().required('Field is required'),
      }),
    interestedcompanies: yup.array().of(
      yup.object().shape({
        companyName: yup
          .string()
          .nullable()
          .test('companyName', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              (!optionsContext?.isDisclosureOfInterestToBeFilledByInvestor ||
                (optionsContext?.isDisclosureOfInterestToBeFilledByInvestor &&
                  (fieldValidateForInvestor(referenceApplicantType, 1, false) ||
                    validateFormIncludingAllJointHoldersInInvestorLogin))) &&
              YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              if (!value) {
                return createError({
                  message: 'Company name is required',
                  path,
                });
              } else if (!mdmsCompanyList.map((company) => company.symbol).includes(value)) {
                return createError({
                  message: 'Invalid Company Name',
                  path,
                });
              }
            }
            return true;
          }),

        // natureOfInterest: yup
        //   .string()
        //   .nullable()
        //   .test('natureOfInterest', (value, context) => {
        //     const {
        //       options: { context: optionsContext },
        //       createError,
        //       path,
        //       parent,
        //     } = context;
        //     if (
        //       !value &&
        //       YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
        //       parent.isActive
        //     ) {
        //       return createError({
        //         message: 'Nature Of Interest required',
        //         path,
        //       });
        //     }

        //     return true;
        //   }),

        // numberOfShares: yup
        //   .string()
        //   .nullable()
        //   .test('numberOfShares', (value, context) => {
        //     const {
        //       options: { context: optionsContext },
        //       createError,
        //       path,
        //       parent,
        //     } = context;
        //     if (
        //       !value &&
        //       YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
        //       parent.isActive
        //     ) {
        //       return createError({
        //         message: 'Number Of Share required',
        //         path,
        //       });
        //     }
        //     if (
        //       value &&
        //       YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
        //       parent.isActive &&
        //       !numberRegex.test(value)
        //     ) {
        //       return createError({
        //         message: 'Field value should be a number',
        //         path,
        //       });
        //     }
        //     return true;
        //   }),

        // percentageofPaidUpCapital: yup
        //   .string()
        //   .nullable()
        //   .test('percentageofPaidUpCapital', (value, context) => {
        //     const {
        //       options: { context: optionsContext },
        //       createError,
        //       path,
        //       parent,
        //     } = context;
        //     if (
        //       !value &&
        //       YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
        //       parent.isActive
        //     ) {
        //       return createError({
        //         message: '% of Paid Up Capital required',
        //         path,
        //       });
        //     }
        //     if (
        //       value &&
        //       YesNoMaster[optionsContext?.interestedCompaniesCheck] === YesNoMaster.yes &&
        //       parent.isActive &&
        //       !numberRegex.test(value)
        //     ) {
        //       return createError({
        //         message: 'Field value should be a number',
        //         path,
        //       });
        //     }
        //     return true;
        //   }),
      })
    ),
  });

export const disclosureOfExcludedSchema = (
  referenceApplicantType: string,
  mdmsCompanyList: mdmsCompaniesList[],
  validateFormIncludingAllJointHoldersInInvestorLogin?: boolean
) =>
  yup.object().shape({
    excludedCompaniesCheck: yup
      .string()
      .nullable()
      .when('isDisclosureOfExclusionsToBeFilledByInvestor', {
        is: (isDisclosureOfExclusionsToBeFilledByInvestor: boolean) =>
          !isDisclosureOfExclusionsToBeFilledByInvestor ||
          (isDisclosureOfExclusionsToBeFilledByInvestor &&
            (fieldValidateForInvestor(referenceApplicantType, 1, false) ||
              validateFormIncludingAllJointHoldersInInvestorLogin)),
        then: yup.string().nullable().required('Field is required'),
      }),
    excludedcompanies: yup.array().of(
      yup.object().shape({
        companySectorType: yup
          .string()
          .nullable()
          .test('companySectorType', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              (!optionsContext?.isDisclosureOfInterestToBeFilledByInvestor ||
                (optionsContext?.isDisclosureOfInterestToBeFilledByInvestor &&
                  (fieldValidateForInvestor(referenceApplicantType, 1, false) ||
                    validateFormIncludingAllJointHoldersInInvestorLogin))) &&
              YesNoMaster[optionsContext?.excludedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              if (!value) {
                return createError({
                  message: 'Company type required',
                  path,
                });
              } else if (!CompanyMasters.includes(value)) {
                return createError({
                  message: 'Invalid Company type',
                  path,
                });
              }
            }

            return true;
          }),

        companySectorName: yup
          .string()
          .nullable()
          .test('companySectorName', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            if (
              (!optionsContext?.isDisclosureOfInterestToBeFilledByInvestor ||
                (optionsContext?.isDisclosureOfInterestToBeFilledByInvestor &&
                  (fieldValidateForInvestor(referenceApplicantType, 1, false) ||
                    validateFormIncludingAllJointHoldersInInvestorLogin))) &&
              YesNoMaster[optionsContext?.excludedCompaniesCheck] === YesNoMaster.yes &&
              parent.isActive
            ) {
              if (!value) {
                return createError({
                  message: 'Name of Company/ Industry/ Sector required',
                  path,
                });
              } else if (
                !mdmsCompanyList.map((company) => company.symbol).includes(value) &&
                parent.companySectorType === 'Company'
              ) {
                return createError({
                  message: 'Invalid Name of Company/ Industry/ Sector',
                  path,
                });
              } else if (parent.companySectorType !== 'Company' && !value.match(stringRegex)) {
                return createError({
                  message: 'Special characters and numbers not allowed',
                  path,
                });
              }
            }
            return true;
          }),
      })
    ),
  });

export const StrategyDetailSchema = yup.object().shape({
  investmentAmount: yup
    .number()
    .nullable()
    .min(5000000, 'Total Investment Amount (in INR) must be greater than or equal to 50 lakhs')
    .required('Total Investment Amount (in INR) is required'),
});

export const documentDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      documents: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (
                        documentType === 'bankAccountProof' &&
                        !fieldValue &&
                        banks &&
                        banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          .length !== 0 &&
                        Number(indexes[0]) === 0
                      ) {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                      if (documentType === 'bankAccountProof' && Number(indexes[0]) !== 0) {
                        return true;
                      }
                      if (
                        !fieldValue &&
                        required === 'true' &&
                        documentType !== 'bankAccountProof'
                      ) {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentNumber: yup
                .string()
                .nullable()
                .test(
                  'documentNumber',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];

                    if (optionsDropdown) {
                      if (
                        !value &&
                        documentTypeValidationCheckForDocumentNumberDocumentExpiryDate(
                          documentType
                        ) &&
                        Number(indexes[2]) === 0
                      ) {
                        return createError({
                          message: `Document Number is required`,
                          path,
                        });
                      }
                    }

                    if (
                      value &&
                      !alphaNumeric.test(value) &&
                      documentTypeValidationCheckForDocumentNumberDocumentExpiryDate(documentType)
                    ) {
                      return createError({
                        message: 'Special Characters are not allowed',
                        path,
                      });
                    }

                    return true;
                  }
                ),
              documentExpiryDate: yup
                .string()
                .nullable()
                .test(
                  'documentExpiryDate',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    if (optionsDropdown) {
                      if (
                        !value &&
                        documentTypeValidationCheckForDocumentNumberDocumentExpiryDate(
                          documentType
                        ) &&
                        documentNameValidationCheckForDocumentExpiryDate(documentName) &&
                        Number(indexes[2]) === 0
                      ) {
                        return createError({
                          message: `Expiry Date is required`,
                          path,
                        });
                      }
                      // if (
                      //   value &&
                      //   documentTypeValidationCheckForDocumentNumberDocumentExpiryDate(
                      //     documentType
                      //   ) &&
                      //   documentNameValidationCheckForDocumentExpiryDate(documentName) &&
                      //   Number(indexes[2]) === 0 &&
                      //   new Date(new Date(value).setHours(0, 0, 0, 0)) <
                      //     new Date(
                      //       new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0, 0)
                      //     )
                      // ) {
                      //   return createError({
                      //     message: `Date is Invalid`,
                      //     path,
                      //   });
                      // }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName, documentType } =
                      applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value) {
                      if (
                        !value &&
                        banks &&
                        banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          .length !== 0 &&
                        Number(indexes[0]) === 0 &&
                        documentType === 'bankAccountProof'
                      ) {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                      if (!value && required === 'true' && documentType !== 'bankAccountProof') {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
  nominees: yup.array().of(
    yup.object().shape({
      nomineedocuments: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { nominees = [] } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (!fieldValue && required === 'true') {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { nominees = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName, documentType } =
                      nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value && required === 'true') {
                      return createError({
                        message: `Please upload ${documentName}`,
                        path,
                      });
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
  // applicants: yup.array().of(
  //   yup.object().shape({
  //     documents: yup.object().shape({
  //       // identityProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select identity proof'),
  //       //   documentId: yup.string().required('Please upload Identity Proof'),
  //       // }),
  //       // addressProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select Address Proof'),
  //       //   documentId: yup.string().required('Please upload Address Proof'),
  //       // }),
  //       bankAccountProof: yup.object().shape({
  //         documentName: yup
  //           .string()
  //           .test('documentName', 'Please select Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),

  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),
  //       }),
  //       poaNotarized: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload POA Notarized Document', (value, context) =>
  //             POADocumentValidation(value, context, 'poaNotarized')
  //           ),
  //       }),
  //       investorsConsent: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', "Please upload Investor's Consent Document", (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //       fatca: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload FATCA Document', (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //     }),
  //   })
  // ),
});

export const documentDetailsSchemaForInvestorLogin = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      documents: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName, documentType } =
                      applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value && required === 'true' && documentType === 'investorSignature') {
                      return createError({
                        message: `Please upload ${documentName}`,
                        path,
                      });
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
});

export const distributorsValidationSchema = (
  selectedDistributor: SubDistributor[],
  distributors: Distributor[],
  isSupportRM: boolean,
  rmsList: RMType[]
) =>
  yup.object().shape({
    //role: useSelector((store: RootStateType) => store.auth),
    email: yup.string().nullable().matches(emailRegex, 'Invalid RM email id'),
    firstName: yup
      .string()
      .nullable()
      .matches(defaultRegexWithSingleLine, 'Special characters and numbers not allowed'),
    distributorId: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup
          .string()
          .nullable()
          .required('Please select a Distributor Name')
          .test('distributorId', 'Invalid Distributor Name', (distributorId?: string | null) =>
            distributorId
              ? distributors.map((dis) => Number(dis.id)).includes(Number(distributorId))
              : false
          ),
      }),
    subdistributorId: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: () => 'distributor' && !!selectedDistributor.length,
        then: yup
          .string()
          .nullable()
          .required("Please select a Distributor's RM Name")
          .oneOf(
            selectedDistributor.map((dis) => dis.id.toString()),
            "Invalid Distributor's RM Name"
          ),
      }),
    subdistributorEmail: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: (distributorId: string) =>
          distributorId && 'distributor' && !!selectedDistributor.length,
        then: yup
          .string()
          .nullable()
          .required("Distributor's RM Email id is required")
          .matches(emailRegex, "Invalid Distributor's RM Email id"),
      }),
    distributorEmail: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: (loginType: string) =>
          !!distributors.length && loginType === 'distributor' && !selectedDistributor.length,
        then: yup
          .string()
          .nullable()
          .required('Distributor Email id is required')
          .matches(emailRegex, 'Invalid Distributor Email id'),
      }),
    distributorPhone: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: (loginType: string) =>
          !!distributors.length && loginType === 'distributor' && !selectedDistributor.length,
        then: yup
          .string()
          .nullable()
          .required('Distributor Phone Number is required')
          .matches(phoneRegExp, 'Invalid Distributor Phone Number'),
      }),
    subdistributorPhone: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: (distributorId: string) => distributorId && !!selectedDistributor.length,
        then: yup
          .string()
          .nullable()
          .required('Distributor Phone Number is required')
          .matches(phoneRegExp, 'Invalid Distributor Phone Number'),
      }),
    rm_branch: yup
      .string()
      .nullable()
      .required('Please select a AMC Branch')
      .oneOf(Object.keys(AmcBranchMaster), 'Invalid AMC Branch'),
    distributor_branch: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup
          .string()
          .nullable()
          .matches(defaultRegexWithSingleLine, 'Invalid Value')
          .required('Distributor Location is required'),
      }),
    rm_id: yup
      .string()
      .nullable()
      .test('rm_id', (value, context) => {
        const { createError, path, parent } = context;
        if (!value && isSupportRM) {
          return createError({
            message: "AMC's RM is Required",
            path,
          });
        }
        if (value && isSupportRM && !rmsList.map((rm) => Number(rm.id)).includes(Number(value))) {
          return createError({
            message: "Invalid AMC's RM",
            path,
          });
        }
        return true;
      }),
    subdistributorCode: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup
          .string()
          .nullable()
          .matches(defaultRegexWithSingleLine, 'Invalid Sub Distributor Code'),
      }),
    subdistributorEuin: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid EUIN'),
      }),
    distributorChannel: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup
          .string()
          .nullable()
          .test('distributorChannel', (value, context) => {
            const { createError, path } = context;
            if (
              value &&
              !DistributorChannelMaster.map(
                (_distributorChannel) => _distributorChannel.key
              ).includes(value)
            ) {
              return createError({
                message: 'Invalid Distributor Channel',
                path,
              });
            }
            return true;
          }),
      }),
    custodian: yup
      .string()
      .nullable()
      .test('custodian', (value, context) => {
        const { createError, path } = context;
        if (value && !CustodianMaster.includes(value)) {
          return createError({
            message: 'Invalid value for Custodian',
            path,
          });
        }
        return true;
      }),
  });
const validateNonIndividualPincode = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } =
    options as InternalOptions<NonIndividualContactValues> & {
      index: number;
    };
  const { applicants = [] } = optionsContext || {};
  if (fieldValue && parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode code should be 6 digit code',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }
  return true;
};

const addressBaseSchema = yup.object().shape({
  pincode: yup
    .string()
    .nullable()
    .test('pincode', (value, context) => validateNonIndividualPincode(value, context)),
  state: yup.string().nullable().matches(stringRegex, 'Special characters and numbers not allowed'),
  city: yup.string().nullable().matches(stringRegex, 'Special characters and numbers not allowed'),
  country: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  buildingNo: yup
    .string()
    .nullable()
    .matches(addressRegex, `Special characters are not allowed except -/',&()#:.`),
  streetName: yup
    .string()
    .nullable()
    .matches(addressRegex, `Special characters are not allowed except -/',&()#:.`),
});

export const userManagementRmSchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .required('RM Name is required')
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Phone number')
    .max(16, 'Invalid Phone number')
    .matches(phoneRegExp, 'Invalid Phone number')
    .required('Phone Number is required'),
  roleType: yup
    .string()
    .nullable()
    .required('Role Type is required')
    .oneOf(Object.keys(RoleTypes), 'Invalid Role Type'),
  panNumber: yup
    .string()
    .nullable()
    .uppercase()
    .matches(individualPanRegex, 'Only individual PANs are allowed currently'),
  empCode: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid Emp Code'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
});

export const DistributorSchema = yup.object().shape({
  name: yup.string().nullable().required('Distributor Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().required('Email is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Contact number')
    .max(16, 'Invalid Contact number')
    .matches(phoneRegExp, 'Invalid Contact number')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('Contact Number is required'),
    }),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value && parent.type=== 'individual') {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value&&parent.type=== 'individual') {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  arnCode: yup
    .string()
    .nullable()
    .matches(arnCodeRegex, 'Invalid ARN Code Ex:- ARN-12345')
    .required('ARN Code is required'),
  // rmId: yup
  //   .string()
  //   .nullable()
  //   .when('type', {
  //     is: (value: string) => value === 'individual',
  //     then: yup.string().nullable().required('RM Name is required'),
  //   }),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const TrusteeAndFundManagerAMCAuthorisedSchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .required('Name is required')
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test(
      'dateOfBirth',
      'Age should be greater than 18',
      (dateOfBirth) => !isMinor(dateOfBirth || '')
    ),
  leegalityName: yup
    .string()
    .nullable()
    .required('Leegality Name is required')
    .matches(defaultRegexWithSingleLine, 'Invalid value'),
  // .required('Date of Birth is required'),
  panNumber: yup
    .string()
    .nullable()
    .uppercase()
    .matches(individualPanRegex, 'Only individual PANs are allowed currently'),
  //   .required('PAN is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const aifApproverSchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .required('Name is required')
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const addFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid mobile number')
    .max(16, 'Invalid mobile number')
    .matches(phoneRegExp, 'Invalid mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const AuthorisedSignatoryAndPOASchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number')
    .required('Mobile Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // distributorId: yup.string().required('Please Enter Distridutor Id'),
  // amcId: yup.string().required('Please Enter AmcId'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const DistributorRmSchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required("Distributor's RM name is required"),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Contact number')
    .max(16, 'Invalid Contact number')
    .matches(phoneRegExp, 'Invalid Contact number')
    .required('Contact Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  distributors_RM_Code: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid RM Code'),
  subDistributorCode: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Sub Distributor Code'),
  euin: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid EUIN'),
  // rmId: yup.string().nullable().required("AMC's RM name is required"),
  panNumber: yup
    .string()
    .uppercase()
    .nullable()
    .matches(individualPanRegex, 'Only individual PANs are allowed currently'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

// name,
// email,
// countryCode,
// phone,
// panNumber,
// distributors_RM_Code,
// rmId,
// distributorId,
// pincode,
// buildingNo,
// streetName,
// city,
// state,
// country,

// pan: yup
// .string()
// .nullable()
// .uppercase()
// .length(10, 'PAN must be exactly 10 characters')
// .matches(individualPanRegex, 'Only individual PANs are allowed')
// .test('pan', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesPanNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'PAN is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
//     return createError({
//       message:
//         'There is already same pan number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),

// mobile: yup
// .string()
// .nullable()
// .min(8, 'Invalid Mobile Number')
// .max(16, 'Invalid Mobile Number')
// .matches(phoneRegExp, 'Invalid Mobile Number')
// .test('mobile', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesMobileNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Mobile Number is required',
//       path,
//     });
//   }
//   if (
//     authorisedSignatoriesMobileNumbers &&
//     authorisedSignatoriesMobileNumbers.includes(value)
//   ) {
//     return createError({
//       message:
//         'There is already same mobile number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
// email: yup
// .string()
// .nullable()
// .matches(emailRegex, 'Invalid Email ID')
// .test('email', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesEmailID = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Email ID is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
//     return createError({
//       message:
//         'There is already same Email ID for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
export const amcAdminSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required('Name is required')
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
});

export const NonIndividualContributorValidationSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable().required('Name Of Entity is required'),
      cityOfIncorporation: yup
        .string()
        .nullable()
        .required('Place of Registration/Incorporation is required'),
      dateOfBirth: yup
        .string()
        .nullable()
        .required('Date of Registration/Incorporation is required'),
      ckycNo: yup
        .string()
        .nullable()
        .test('ckycNo', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const {
            options: { context: optionsContext },
            createError,
            path,
          } = context;
          const { applicants } = optionsContext || {};
          if (!fieldValue) {
            return true;
          } else if (fieldValue.length !== 14) {
            return createError({
              message: 'Invalid CKYC No./CKYC Acknowledge No./KIN No',
              path,
            });
          } else if (!numberRegex.test(fieldValue)) {
            return createError({
              message: 'CKYC No./CKYC Acknowledge No./KIN No allows only digits',
              path,
            });
          } else if (
            applicants.filter((applicant: Applicant) => applicant.ckycNo === fieldValue).length > 1
          ) {
            return createError({
              message:
                'There is already same CKYC Number for an applicant associated with this application',
              path,
            });
          }
          return true;
        }),
      panNo: yup
        .string()
        .nullable()
        .uppercase()
        .length(10, 'PAN must be exactly 10 characters')
        .matches(nonIndividualPanRegex, 'Invalid PAN')
        .required('PAN is required'),
      investorType: yup
        .string()
        .nullable()
        .oneOf(Object.keys(investorTypeMasters), 'Invalid value for Investor Type')
        .required('Please Select Investor Type'),
      netWorth: yup
        .string()
        .nullable()
        .test('netWorth', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError } = context;
          if (!fieldValue) {
            return createError({
              message: 'Net Worth is required',
              path,
            });
          }
          if (!amountRegex.test(fieldValue)) {
            return createError({
              message: 'Invalid amount',
              path,
            });
          }
          return true;
        }),
      // netWorthDate: yup
      //   .string()
      //   .nullable()
      //   .test('netWorthDate', (value, context) => {
      //     const fieldValue = value === undefined ? '' : value;
      //     const { path, createError, parent } = context;
      //     if (!fieldValue) {
      //       return createError({
      //         message: 'Networth as on Date Required',
      //         path,
      //       });
      //     }
      //     if (futureAge(fieldValue || '')) {
      //       return createError({
      //         message: 'Invalid Date',
      //         path,
      //       });
      //     }
      //     return true;
      //   }),
      grossAnnualIncome: yup
        .string()
        .nullable()
        .required('Gross Annual Income is required')
        .oneOf(Object.keys(grossAnnualMasters), 'Invalid value for Gross Annual Income'),
      // grossAnnualIncomeDate: yup
      //   .string()
      //   .nullable()
      //   .test('grossAnnualIncomeDate', (value, context) => {
      //     const fieldValue = value === undefined ? '' : value;
      //     const { path, options, createError, parent } = context;
      //     const { context: optionsContext, index } = options as InternalOptions<Values> & {
      //       index: number;
      //     };
      //     if (!fieldValue) {
      //       return createError({
      //         message: 'Gross Annual Income as on Date Required',
      //         path,
      //       });
      //     }
      //     if (futureAge(fieldValue || '')) {
      //       return createError({
      //         message: 'Invalid Date',
      //         path,
      //       });
      //     }
      //     return true;
      //   }),
    })
  ),
});

const validateNonIndividualPincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } =
    options as InternalOptions<NonIndividualContactValues> & {
      index: number;
    };
  const { applicants = [] } = optionsContext || {};
  if (!fieldValue && addressType !== 'contactperson') {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode code should be 6 digit code',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }
  return true;
};

export const nonIndividualContactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      contactperson: yup.object().shape({
        address1: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('Address line 1 is required')
        address2: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('Address line 2 is required')
        // address3: yup
        //   .string()
        //   .nullable()
        //   .matches(
        //     addressRegex,
        //     "Invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
        //   ),
        landLineNumber: yup
          .string()
          .nullable()
          .test('landLineNumber', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.stdCode) {
              return createError({
                message: 'Please enter Landline number',
                path,
              });
            } else if (parent.landLineNumber && !numberRegex.test(parent.landLineNumber)) {
              return createError({
                message: 'Please enter valid Landline Number',
                path,
              });
            }
            return true;
          }),
        stdCode: yup
          .string()
          .nullable()
          .test('stdCode', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.landLineNumber) {
              return createError({
                message: 'Please enter STD Code',
                path,
              });
            } else if (parent.stdCode && !numberRegex.test(parent.stdCode)) {
              return createError({
                message: 'Please enter valid STD Code',
                path,
              });
            }
            return true;
          }),

        city: yup.string().nullable(),
        // .required('City is required')
        state: yup.string().nullable(),
        // .required('State is required')
        country: yup
          .string()
          .nullable()
          .test('country', (value, context) => {
            const { options, createError, path } = context;
            const { context: optionsContext } =
              options as InternalOptions<NonIndividualContactValues>;
            const { countryDropdown = [] } = optionsContext || {};
            // if (!value) {
            //   return createError({
            //     message: 'Country is required',
            //     path,
            //   });
            // }
            if (value && !countryDropdown.includes(value)) {
              return createError({
                message: 'Invalid value for Country',
                path,
              });
            }
            return true;
          }),
        pincode: yup
          .string()
          .nullable()
          .test('pincode', (value, context) =>
            validateNonIndividualPincodeField(value, context, 'contactperson')
          ),
        name: yup.string().nullable().required('Name is required'),
        email: yup
          .string()
          .nullable()
          .matches(emailRegex, 'Invalid Email ID')
          .required('Email is required'),
        mobile: yup
          .string()
          .nullable()
          .test('mobile', (value, context) => {
            const { createError, path, parent } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === parent.countryNameAndCode) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value) {
              return createError({
                message: 'Mobile is required',
                path,
              });
            }
            if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }
            if (!isValidPhoneNumber(value, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
        countryNameAndCode: yup
          .string()
          .nullable()
          .test('countryNameAndCode', (value, context) => {
            const { createError, path, parent } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === value) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value) {
              return createError({
                message: 'Country Code is required',
                path,
              });
            }
            if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
      }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .required('Address line 1 is required'),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              if (!fetchedFromKRA && !value) {
                return false;
              }
              return true;
            }),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validateNonIndividualPincodeField(value, context, 'correspondence')
            ),
          city: yup.string().nullable().required('City is required'),
          state: yup.string().nullable().required('State is required'),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext } =
                options as InternalOptions<NonIndividualContactValues>;
              const { countryDropdown = [] } = optionsContext || {};
              if (!value) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (!countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA) {
                return validatePermanentAddressFields(
                  value,
                  context,
                  APPLICATION_TYPE.NON_INDIVIDUAL
                );
              }
              return true;
            }),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 3 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                return validateNonIndividualPincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } =
                options as InternalOptions<NonIndividualContactValues> & {
                  index: number;
                };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (!countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // .test('country', 'Country is required', (value, context) =>
          //   validatePermanentAddressFields(value, context)
          // ),
        }),
      }),
    })
  ),
});

export const nonIndividualDocumentDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      documents: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, NonIndividualDocValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<NonIndividualDocValues> & { index: number };
                    const { applicants = [], banks } =
                      optionsContext || ({} as NonIndividualDocValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (
                        documentType === 'bankAccountProof' &&
                        !fieldValue &&
                        banks &&
                        banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          .length !== 0
                      ) {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                      if (
                        !fieldValue &&
                        required === 'true' &&
                        documentType !== 'bankAccountProof'
                      ) {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, NonIndividualDocValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<NonIndividualDocValues> & { index: number };
                    const { applicants = [], banks } =
                      optionsContext || ({} as NonIndividualDocValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName, documentType } =
                      applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value) {
                      if (
                        !value &&
                        documentType === 'bankAccountProof' &&
                        banks &&
                        banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          .length !== 0
                      ) {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                      if (required === 'true' && documentType !== 'bankAccountProof') {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
});

export const nonIndividualAuthorisedSignatories = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  pan: yup
    .string()
    .nullable()
    .uppercase()
    .length(10, 'PAN must be exactly 10 characters')
    .matches(individualPanRegex, 'Only individual PANs are allowed')
    .test('pan', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesPanNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
        .flat();
      if (!value) {
        return createError({
          message: 'PAN is required',
          path,
        });
      }
      if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same pan for an Authorized Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  mobile: yup
    .string()
    .nullable()
    .test('mobile', (value, context) => {
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesMobileNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
        .flat();
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (
        authorisedSignatoriesMobileNumbers &&
        authorisedSignatoriesMobileNumbers.includes(value)
      ) {
        return createError({
          message:
            'There is already same mobile number for an Authorized Signatory associated with this application',
          path,
        });
      }

      return true;
    }),

  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid Email ID')
    .test('email', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesEmailID = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
        .flat();
      if (!value) {
        return createError({
          message: 'Email ID is required',
          path,
        });
      }
      if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
        return createError({
          message:
            'There is already same Email ID for an Authorized Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  designation: yup.string().nullable().required('Designation is required'),
});

const fieldValidation = (
  value: string | undefined | null | number,
  context: yup.TestContext<Record<string, ubo>>,
  mes: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;
  if (!fieldValue && !parent.isFetchData) {
    return createError({
      message: `${mes} is required`,
      path,
    });
  }
  return true;
};

export const nonIndividualUbo = yup.object().shape({
  panNumber: yup
    .string()
    .nullable()
    .uppercase()
    .test('panNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ubo[]>;

      const uboPanNumbers = optionsContext?.map((ubo) => ubo.panNumber);
      if (!value) {
        return createError({
          message: 'Taxpayer ID Number/PAN/Equivalent ID Number is required',
          path,
        });
      }
      if (uboPanNumbers && uboPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same Taxpayer ID Number/PAN/Equivalent ID Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
          path,
        });
      }

      return true;
    }),

  dob: yup
    .string()
    .nullable()
    .test('dob', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (!fieldValue) {
        return createError({
          message: 'Date of birth is required',
          path,
        });
      }
      if (
        parent.panNumber &&
        individualPanRegex.test(parent.panNumber) &&
        isMinor(fieldValue || '')
      ) {
        return createError({
          message: 'Age should be greater than 18',
          path,
        });
      }
      if (
        parent.panNumber &&
        individualPanRegex.test(parent.panNumber) &&
        maxAge(fieldValue || '')
      ) {
        return createError({
          message: 'Age should be less than 125',
          path,
        });
      }
      return true;
    }),
  name: yup
    .string()
    .nullable()
    .test('name', (value, context) => {
      return fieldValidation(value, context, 'Name');
    }),
  identificationType: yup
    .string()
    .nullable()
    .test('identificationType', (value, context) => {
      return fieldValidation(value, context, 'Identification Type');
    }),
  percentageOfBeneficialInterest: yup
    .number()
    .nullable()
    .test('percentageOfBeneficialInterest', (value, context) => {
      const fieldValue = value;
      const { path, createError, parent } = context;
      if (fieldValue === null || (fieldValue === undefined && !parent.isFetchData)) {
        return createError({
          message: `Percentage Of Beneficial Interest is required`,
          path,
        });
      }
      if (fieldValue === 0 && !parent.isFetchData) {
        return createError({
          message: `Percentage Of Beneficial Interest should be greater 0`,
          path,
        });
      }
      if (fieldValue) {
        if (fieldValue < 0 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest should be greater 0`,
            path,
          });
        }
        if (fieldValue > 100 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest must not exceed 100%`,
            path,
          });
        }
      }
      return true;
    }),
  countryOfTaxResidency: yup
    .string()
    .nullable()
    .test('countryOfTaxResidency', (value, context) => {
      return fieldValidation(value, context, 'Country Of Tax Residency');
    }),
  cpUboCode: yup
    .string()
    .nullable()
    .oneOf(Object.keys(CpUboCodesMaster), 'Invalid value for CP/UBO Code')
    .test('cpUboCode', (value, context) => {
      return fieldValidation(value, context, 'Cp/UboCode');
    }),
  placeAndCountryOfBirth: yup
    .string()
    .nullable()
    .test('placeAndCountryOfBirth', (value, context) => {
      return fieldValidation(value, context, 'Place And Country Of Birth');
    }),
  occupation: yup
    .string()
    .nullable()
    .test('occupation', (value, context) => {
      return fieldValidation(value, context, 'Occupation');
    })
    .oneOf(Object.keys(occupationDetailsMasters), 'Invalid value for Occupation'),
  gender: yup
    .string()
    .nullable()
    .test('gender', (value, context) => {
      return fieldValidation(value, context, 'Gender');
    }),
  nationality: yup
    .string()
    .nullable()
    // .oneOf(nationalitiesMasters, 'Invalid value for Nationality')
    .test('nationality', (value, context) => {
      return fieldValidation(value, context, 'Nationality');
    }),
  fatherName: yup
    .string()
    .nullable()
    .test('fatherName', (value, context) => {
      return fieldValidation(value, context, 'FatherName');
    }),
  ckycNumber: yup
    .string()
    .nullable()
    .length(14, 'Invalid CKYC Number')
    .test('ckycNumber', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<ubo[]>;
      if (!fieldValue && !parent.isFetchData) {
        return true;
      } else if (!numberRegex.test(fieldValue as string) && !parent.isFetchData) {
        return createError({
          message: 'CKYC Number allows only digits',
          path,
        });
      }
      const uboCkycNumbers = optionsContext?.map((ubo) => Number(ubo.ckycNumber).toString());
      if (uboCkycNumbers && uboCkycNumbers.includes(fieldValue as string) && !parent.isFetchData) {
        return createError({
          message:
            'There is already same CKYC Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
          path,
        });
      }
      return true;
    }),
  address2: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
    )
    .test('address2', (value, context) => {
      return fieldValidation(value, context, 'Address Line 1');
    }),
  address3: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
    )
    .test('address3', (value, context) => {
      return fieldValidation(value, context, 'Address Line 2');
    }),
  city: yup
    .string()
    .nullable()
    .test('city', (value, context) => {
      return fieldValidation(value, context, 'City');
    }),
  state: yup
    .string()
    .nullable()
    .test('state', (value, context) => {
      return fieldValidation(value, context, 'State');
    }),
  country: yup
    .string()
    .nullable()
    .test('country', (value, context) => {
      return fieldValidation(value, context, 'Country');
    }),
  pincode: yup
    .string()
    .nullable()
    .test('pincode', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (!fieldValue && !parent.isFetchData) {
        return createError({
          message: 'Pincode is required',
          path,
        });
      } else if (
        parent.country?.toLowerCase() === 'india' &&
        !indianPin.test(fieldValue as string) &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Pincode length should be 6 digits',
          path,
        });
      } else if (
        parent.country?.toLowerCase() !== 'india' &&
        !alphaNumeric.test(fieldValue as string) &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Invalid Pincode',
          path,
        });
      }
      return true;
    }),
});

export const nonIndividualControllingPersons = yup.object().shape({
  name: yup.string().nullable().required('Name of controlling person is required'),
  correspondenceAddress: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid address format [special characters are not allowed except -/',&()#:.]"
    )
    .required('Correspondence address is required'),
  countryOfResidense: yup
    .string()
    .nullable()
    .required('Country of residence for tax purposes is required'),
  issuingCountry: yup.string().nullable().required('Issuing country is required'),
  controllingPersonType: yup.string().nullable().required('Controlling Person Type is required'),
  indentificationType: yup.string().nullable().required('Identification Type is required'),
  indentificationNumber: yup
    .string()
    .nullable()
    .test('indentificationNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const indentificationNumberArr = optionsContext?.map(
        (person) => person.indentificationNumber
      );
      if (!value) {
        return createError({
          message: 'Identification Number is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (indentificationNumberArr && indentificationNumberArr.includes(value)) {
        return createError({
          message:
            'There is already same Identification Number for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
  occupationType: yup.string().nullable().required('Occupation Type is required'),
  occupation: yup
    .string()
    .nullable()
    .required('Occupation is required')
    .oneOf(Object.keys(occupationDetailsMasters), 'Invalid value for Occupation'),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('dateOfBirth', (value, context) => {
      const { path, createError } = context;
      if (!value) {
        return createError({
          message: 'Date of Birth is required',
          path,
        });
      }
      if (isMinor(value)) {
        return createError({
          message: 'Age should be greater than 18',
          path,
        });
      }
      if (maxAge(value)) {
        return createError({
          message: 'Age should be less than 125',
          path,
        });
      }
      return true;
    }),
  countryOfBirth: yup.string().nullable().required('Country Of Birth is required'),
  nationality: yup.string().nullable().required('Nationality is required'),
  tin: yup
    .string()
    .nullable()
    .test('tin', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const controllingPersons = optionsContext?.map((person) => person.tin);
      if (!value) {
        return createError({
          message: 'TIN is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (controllingPersons && controllingPersons.includes(value)) {
        return createError({
          message:
            'There is already same Tin for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
});

type errorObj = { fieldPath: string; fieldName: string };

const subQuestionsValidation = (
  fatca: nonIndividualQuestionsFatca,
  path: string
): errorObj | errorObj[] | undefined => {
  if (
    !fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.isMandatory === 'true' &&
    fatca.question_type !== 'Controlling_person_details'
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName:
        fatca.question_type === 'single_choice_radio'
          ? 'Field is required'
          : fatca.question_text
          ? fatca.question_text + ' is required'
          : (fatca.placeholder || 'Field') + ' is required',
    };
  }
  if (
    fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.validation === 'alphaNumeric' &&
    !alphaNumeric.test(fatca.answer)
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName: 'Special Characters are Not allowed',
    };
  }
  if (fatca.question_type === 'group' && fatca.isVisible && fatca.sub_questions?.length) {
    return fatca.sub_questions
      ?.map((qun) => subQuestionsValidation(qun, path) as errorObj)
      .filter((ele: errorObj) => ele)
      .flat();
  }
};

export const nonIndividualFatcaSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      fatcadetail: yup.array().of(
        yup.object().shape({
          answer: yup
            .string()
            .nullable()
            .test('answer', (value, context) => {
              const { path, createError, parent } = context;
              if (
                !value &&
                parent.isVisible &&
                parent.isMandatory === 'true' &&
                parent.backend_key &&
                parent.question_type !== 'single_choice_radio'
              ) {
                return createError({
                  message:
                    parent.question_type === 'open_text_field'
                      ? (parent.placeholder || 'Field') + ' is required'
                      : parent.question_text
                      ? parent.question_text + ' is required'
                      : (parent.placeholder || 'Field') + ' is required',
                  path,
                });
              }
              if (
                value &&
                parent.isVisible &&
                parent.backend_key &&
                parent.validation === 'alphaNumeric' &&
                !alphaNumeric.test(value)
              ) {
                return createError({
                  message: 'Special Characters are Not allowed',
                  path,
                });
              }
              if (parent.question_type === 'group' && parent.isVisible && parent.sub_questions) {
                //subQuestionsValidation(value, context)
                const res: errorObj[] = [];
                const val = parent.sub_questions
                  ?.map((qun: nonIndividualQuestionsFatca) => {
                    const r = subQuestionsValidation(qun, path?.split('.')[0]) as errorObj;
                    return r;
                    // return (r as errorObj)
                    //   ? new yup.ValidationError(r.fieldName, '', `${r.fieldPath}.answer`)
                    //   : (r as unknown as errorObj[])?.map(
                    //       (ele: errorObj) =>
                    //         new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`)
                    //     );
                  })
                  .filter((ele: errorObj) => ele)
                  .flat();
                // .toString();
                if (val.length) {
                  const error = val.map((ele: errorObj) => {
                    return new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`);
                  });
                  if (error.length) {
                    return new yup.ValidationError(error);
                  }

                  // return createError({
                  //   message: 'Field is Required1',
                  //   path: `${val}.answer`,
                  // });
                }
                return true;
              }
              return true;
            }),
        })
      ),
    })
  ),
});

export const addPlanValidation = yup.object().shape({
  planCode: yup.string().nullable().required('Plan Code is required'),
  planDescription: yup.string().nullable().required('Plan Description is required'),
  schemeNature: yup.string().nullable().required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup.string().nullable().required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
    .required('Minimum Commitment Amount is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
    )
    .required('Maximum Commitment Amount is required'),
});

export const addFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
  planCode: yup.string().nullable().required('Plan Code is required'),
  planDescription: yup.string().nullable().required('Plan Description is required'),
  schemeNature: yup.string().nullable().required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup.string().nullable().required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
    .required('Minimum Commitment Amount is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
    )
    .required('Maximum Commitment Amount is required'),
});

export const addStrategyValidation = yup.object().shape({
  strategyName: yup.string().nullable().required('Strategy Name is required'),
  distributorIds: yup
    .array()
    .nullable()
    .when('isApplicableForDistributor', {
      is: (isApplicableForDistributor: boolean) => isApplicableForDistributor === true,
      then: yup
        .array()
        .nullable()
        .min(1, 'Distributors required')
        .required('Distributors required'),
    }),
});

export const addFeeSlabValidation = (distributorsIds: number[]) =>
  yup.object().shape({
    feeName: yup
      .string()
      .nullable()
      .required('Fee Name is required')
      .matches(defaultRegexWithSingleLine, 'Invalid Fee Name'),
    feeSlabId: yup
      .string()
      .nullable()
      .required('Fee Slab Id is required')
      .matches(numberRegex, 'Fee Slab Id allows only digits'),
    feeSlabCode: yup
      .string()
      .nullable()
      .required('Fee Slab Code is required')
      .matches(numberRegex, 'Fee Slab Code allows only digits'),
    mgmtFee: yup
      .number()
      .nullable()
      .required('Management Fee is required')
      .min(0, 'Management Fee should be greater than or equal to 0'),
    optFee: yup
      .number()
      .nullable()
      .required('Operating Fee required')
      .min(0, 'Operating Fee should be greater than or equal to 0'),
    yearEnd: yup
      .string()
      .nullable()
      .required('year End is required')
      .oneOf(Object.keys(YearEndMaster), 'Invalid Year End'),
    feeType: yup
      .string()
      .nullable()
      .required('Fee type is required')
      .oneOf(
        FeeTypeMaster.map((fee) => fee.value),
        'Invalid Fee Type'
      ),
    debitingFrequency: yup
      .string()
      .nullable()
      .required('Debiting Freqency is required')
      .oneOf(Object.keys(debitingFreqMaster), 'Invalid Fee debiting Frequency'),
    distributorIds: yup
      .array()
      .nullable()
      .when('isApplicableForDistributor', {
        is: (isApplicableForDistributor: boolean) => isApplicableForDistributor === true,
        then: yup
          .array()
          .nullable()
          .min(1, 'Distributors required')
          .required('Distributors required')
          .test('distributorIds', (value, context) => {
            const fieldValue = value === null ? [] : value;
            const { createError, path } = context;
            if (
              fieldValue &&
              fieldValue?.length &&
              fieldValue
                ?.map((dis: number) => dis && distributorsIds.includes(Number(dis)))
                .includes(false)
            ) {
              return createError({
                message: 'Invalid Distributor',
                path,
              });
            }
            return true;
          }),
      }),
    exitload: yup.array().of(
      yup.object().shape({
        exitload: yup
          .number()
          .nullable()
          .required('Exit load required')
          .min(0, 'Exit load should be greater than or equal to 0'),
      })
    ),
    hurdlerates: yup
      .array()
      .nullable()
      .when('feeType', {
        is: (value: string) => checkFeeTypeForHuddleRate(value),
        then: yup
          .array()
          .of(
            yup.object().shape({
              from: yup
                .number()
                .nullable()
                .when('isActive', {
                  is: (isActive: boolean) => isActive,
                  then: yup
                    .number()
                    .nullable()
                    .required('From required')
                    .min(0, 'From should be greater than or equal to 0'),
                }),
              to: yup
                .string()
                .nullable()
                // .required('To required')
                .when('isActive', {
                  is: (isActive: boolean) => isActive,
                  then: yup
                    .string()
                    .nullable()
                    .test('to', (value, context) => {
                      const fieldValue = value === null ? '' : value;
                      const { options, createError, path, parent } = context;
                      if (!fieldValue) {
                        return createError({
                          message: 'To required',
                          path,
                        });
                      }
                      //  else if (isNaN(Number(fieldValue)) && !fieldValue.match(alphaNumeric)) {
                      //   return createError({
                      //     message: 'Special characters not allowed',
                      //     path,
                      //   });
                      // }
                      else if (!isNaN(Number(fieldValue)) && Number(fieldValue) < 0) {
                        return createError({
                          message: 'To should be greater than or equal to 0',
                          path,
                        });
                      }
                      return true;
                    }),
                }),
              profit: yup
                .number()
                .nullable()
                .when('isActive', {
                  is: (isActive: boolean) => isActive,
                  then: yup
                    .number()
                    .nullable()
                    .required('Profit required')
                    .min(0, 'Profit should be greater than or equal to 0'),
                }),
            })
          )
          .min(1, 'Hurdle rates required')
          .required('Hurdle rates required'),
        otherwise: yup.array().nullable(),
      }),
  });

export const editFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
});

export const portfolioManagerSchema = yup.object().shape({
  portfolioManagerName: yup.string().nullable().required('Portfolio manager name is required'),
  sebiRegistrationNumber: yup
    .string()
    .nullable()
    .matches(alphaNumeric, 'Special characters are not allowed')
    .required('SEBI registration number is required'),
  investorRelationOfficerName: yup
    .string()
    .nullable()
    .required('Investor relations officer name is required'),
  investorRelationOfficerMobileNumber: yup
    .string()
    .nullable()
    .required('Investor relations officer contact details is required'),
  portfolioManagerMobileNumber: yup
    .string()
    .nullable()
    .required('Portfolio manager contact details is required'),
  pmFee: yup.string().nullable().required('Fees & charges including exit load is required'),
  tenureOfPMS: yup.string().nullable().required('Tenure of PMS is required'),
});
